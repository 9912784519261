@use 'sass:math';
@use '../../variables';
@use 'field-variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';

@mixin input-border {
  input :not(.onep-checkbox-native-input) {
    border: 1px solid colors.grey('light');

    &:focus {
      border: 1px solid colors.grey('light-black');
      box-shadow: inset 0 0 0 1px colors.grey('light-black');
    }
  }
}

@mixin style {
  input {
    -webkit-appearance: none;
    height: 18px;
    font-family: var(--description-font), sans-serif;
  }

  .onep-input {
    padding: variables.$spacing * 3 variables.$spacing * 2 variables.$spacing
      variables.$spacing * 2;
    border: 1px solid colors.grey('light');
    border-radius: math.div(variables.$spacing, 2);

    background-color: variables.$white;

    flex: 1;

    color: colors.grey('light-black');
    font-size: field-variables.$input-font-size;
    line-height: 20px;

    outline: none;

    &:focus {
      border: 1px solid colors.grey('light-black');
      box-shadow: 0 0 0 1px colors.grey('light-black');
    }

    &:disabled {
      background-color: colors.grey('extra-light');
      color: colors.grey('medium');
    }

    &.onep-input-error {
      border: 1px solid colors.orange('error');
      box-shadow: 0 0 0 1px colors.orange('error');
      outline: none;
    }

    &.onep-autocomplete.overlay-open {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  onep-conditions-helper {
    ul {
      padding: 0;
      margin: variables.$spacing * 2 0 0;
      list-style: none;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      li {
        flex: 0 0 45%;
        margin-right: 32px;
        margin-bottom: variables.$spacing;
        onep-condition-helper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          color: colors.grey('medium');

          span {
            display: flex;
            flex-direction: column;
            align-self: center;
            flex: 5;
            font-size: variables.$small-font-size;
          }
          svg {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            margin-right: math.div(variables.$spacing, 2);
          }
          &.valid-condition {
            color: colors.grey('light-black');
            svg {
              color: colors.green('success');
            }
          }
        }
      }

      li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .onep-conditions-helper-mob {
    ul {
      flex-direction: column;

      span {
        flex: inherit;
      }

      svg {
        flex: inherit;
      }
    }
  }

  .onep-field-label {
    z-index: 2;
    position: absolute;
    left: variables.$spacing * 2;
    top: variables.$spacing * 2;

    @include text.body-2;
    color: colors.grey('medium');

    cursor: text;
    user-select: none;

    pointer-events: none;

    transition: top ease-in-out 150ms, font-size ease-in-out 150ms;
    &.onep-no-floating-label {
      @include text.body-1;
      @include text.medium;
      color: colors.grey('light-black');
      position: relative;
      left: 0;
      top: 0;
      margin-bottom: variables.$spacing;
    }
    &.active {
      @include text.caption-2;
      color: colors.grey('medium');
      top: variables.$spacing;
      /* -1px because border is 1px */
    }
    &.disable {
      color: colors.grey('medium');
    }
  }

  onep-field-suffix {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: variables.$spacing * 2 - 1px;
    top: math.div(field-variables.$input-height, 2) - math.div(24px, 2);
    @include mixins.onep-small-and-down {
      top: math.div(field-variables.$input-height-mobile, 2) - math.div(24px, 2);
    }
    width: 24px;
    height: 24px;
  }

  onep-clear-field-suffix,
  onep-password-field-suffix onep-field-suffix {
    button {
      outline: none;
      color: colors.grey('light');
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
      svg {
        font-size: field-variables.$icon-size;
      }
    }
  }

  onep-password-field-suffix onep-field-suffix button {
    color: colors.grey('medium');
  }

  onep-success-field-suffix onep-field-suffix {
    pointer-events: none;
    svg {
      color: colors.green('success');
      font-size: field-variables.$icon-size;
    }
  }

  onep-error-field-suffix onep-field-suffix {
    pointer-events: none;
    svg {
      color: colors.orange('error');
      font-size: field-variables.$icon-size;
    }
  }

  .onep-field-inline {
    margin-top: 8px;
    color: colors.grey('medium');
    font-size: variables.$small-font-size;
    font-weight: 400;
    line-height: 14px;
    font-family: var(--description-font), sans-serif;
    height: 14px;
    &.onep-field-inline-error {
      color: colors.orange('error');
    }
  }
}
