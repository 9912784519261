@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-side-action-container {
    display: flex;
    flex-direction: column;
    padding: variables.$spacing * 3;
    border: 1px solid colors.grey('soft');
    box-sizing: border-box;
    border-radius: 4px;

    onep-side-action-item {
      display: flex;
      align-items: center;
      svg[onepIcon] {
        width: variables.$spacing * 3;
        height: variables.$spacing * 3;
        min-width: variables.$spacing * 3;
        min-height: variables.$spacing * 3;
        margin-right: 4px;
        color: colors.grey('medium');
      }

      .onep-side-action-item-button {
        padding: 0 !important;
        font-weight: 500 !important;
        transition: none !important;
        text-decoration: inherit !important;
        margin-right: variables.$spacing;
        overflow: hidden;
        .onep-button-label {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .onep-side-action-item-button:hover {
        text-decoration: underline !important;
      }
    }

    onep-side-action-item:not(:first-child) {
      margin-top: variables.$spacing * 3;
    }
  }
}
