@use 'sass:math';
@use '../../text';
@use '../../colors';
@use '../../grid';
@use '../../variables';
@use '../../mixins';

@mixin style {
  onep-notification-banner {
    width: 416px;
    cursor: pointer;
    @include text.body-3();
    height: 88px;
    @include grid.onep-small-mobile-only {
      height: 96px;
      width: 280px;
      .onep-notification-banner-content-wrapper {
        width: 100% !important;
      }
    }
    @include grid.onep-horizontal-tablet-only {
      height: 96px;
      .onep-notification-banner-content-wrapper {
        width: 100% !important;
      }
      .onep-notification-banner-container {
        onep-notification-banner-content {
          .onep-notification-banner-description-container {
            width: 300px !important;
          }
        }
      }
    }
    @include grid.onep-vertical-tablet-only {
      height: 96px;
      width: 332px;
      .onep-notification-banner-content-wrapper {
        width: 100% !important;
      }
      .onep-notification-banner-container {
        onep-notification-banner-content {
          .onep-notification-banner-description-container {
            width: 220px !important;
          }
        }
      }
    }
    @include grid.onep-large-mobile-only {
      height: 96px;
      width: 300px;
      .onep-notification-banner-content-wrapper {
        width: 100% !important;
      }
      .onep-notification-banner-container {
        onep-notification-banner-content {
          .onep-notification-banner-description-container {
            width: 188px !important;
          }
        }
      }
    }
    display: flex;
    padding: 0;
    background-color: colors.grey('extra-light');
    border: 1px solid colors.grey('soft');
    box-sizing: border-box;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    .onep-notification-banner-icon {
      @include mixins.onep-small-and-down {
        display: none;
      }
      position: relative;
      transform: rotate(270deg);
      font-size: 24px;
      color: colors.grey('medium');
      margin-left: variables.$spacing;
      margin-right: variables.$spacing;
    }
    .onep-notification-banner-content-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 90%;
      .onep-notification-banner-img {
        position: relative;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        height: 88px;
        width: auto;
        background-color: red;
        @include mixins.onep-small-and-down {
          height: 96px;
          width: 80px;
        }
      }
      .onep-notification-banner-container {
        position: relative;
        display: flex;
        width: 100%;
        overflow: hidden;
        align-self: center;
        flex-direction: column;
        padding: 8px 40px 8px 16px;
        @include mixins.onep-small-and-down {
          padding: 8px 16px 8px 16px;
        }
        onep-notification-banner-title {
          @include text.bold();
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: math.div(variables.$spacing, 2);
        }
        onep-notification-banner-content {
          .onep-notification-banner-description-container {
            @include text.body-3;
            @include mixins.onep-small-and-down {
              @include text.body-1;
            }
            width: 290px;
            display: flex;
            flex-direction: column;
            @include mixins.onep-extra-small-and-down {
              width: 180px !important;
            }
          }
        }
      }
    }
  }
  onep-notification-banner:hover {
    .onep-notification-banner-icon {
      color: colors.grey('dark');
    }
  }
}
