@use '../variables';
@use '../colors';

@mixin style {
  .onep-icon-circle-highlighted {
    position: relative;
    display: inline-flex; // The icon must be an inline component, not a block.

    svg {
      position: absolute;
      z-index: 999;
    }

    div {
      background-color: variables.$white;
      z-index: 998;
      position: absolute;
      border-radius: 50px;
      border: 1px solid colors.grey('light');
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
    }
  }
}
