@use 'variables';
@use 'mixins';
@use 'colors';

@mixin source-serif-pro($font-size, $line-height, $font-weight) {
  color: colors.grey('light-black');
  font-family: var(--standard-font), var(--description-font), serif;
  font-size: calc($font-size - 2px * var(--font-reduction));
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin roboto($font-size, $line-height, $font-weight) {
  color: colors.grey('light-black');
  font-family: var(--description-font), sans-serif;
  font-size: calc($font-size - 1px * var(--font-reduction));
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin robotoCondensed($font-size, $line-height, $font-weight) {
  color: colors.grey('light-black');
  font-family: 'Roboto condensed', sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin heading-1() {
  @include source-serif-pro(34px, 36px, 700);
  letter-spacing: -0.2px;
  @include mixins.onep-small-and-down {
    font-size: 26px;
    line-height: 28px;
  }
}

@mixin heading-2() {
  @include source-serif-pro(26px, 28px, 700);
  letter-spacing: -0.2px;
  @include mixins.onep-small-and-down {
    font-size: 22px;
    line-height: 24px;
  }
}

@mixin subheading-1 {
  @include source-serif-pro(22px, 24px, 600);
  @include mixins.onep-small-and-down {
    @include source-serif-pro(20px, 24px, 700);
  }
  letter-spacing: -0.2px;
}

@mixin subheading-2 {
  @include robotoCondensed(16px, 18px, 700);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 16px;
  }
  letter-spacing: -0.2px;
}

@mixin heading-3 {
  @include source-serif-pro(18px, 20px, 700);
}

@mixin heading-4 {
  @include roboto(20px, 24px, 700);
}

@mixin heading-5 {
  @include roboto(18px, 24px, normal);
  @include mixins.onep-small-and-down {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin body-1 {
  @include roboto(16px, 24px, normal);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 22px;
  }
}

@mixin body-2 {
  @include roboto(16px, 20px, normal);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 16px;
  }
}

@mixin body-2-bold {
  @include roboto(16px, 20px, bold);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 16px;
  }
}

@mixin body-3 {
  @include roboto(14px, 20px, normal);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 16px;
  }
}

@mixin body-3-bold {
  @include roboto(14px, 20px, bold);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 16px;
  }
}

@mixin body-4 {
  @include robotoCondensed(14px, 16px, 700);
}

@mixin caption-1 {
  @include roboto(12px, 18px, normal);
  @include mixins.onep-small-and-down {
    font-size: 12px;
    line-height: 18px;
  }
}

@mixin caption-2 {
  @include roboto(12px, 14px, normal);
  @include mixins.onep-small-and-down {
    font-size: 10px;
    line-height: 12px;
  }
}

@mixin caption-3 {
  @include robotoCondensed(12px, 14px, 700);
}

@mixin link-1 {
  @include roboto(16px, 20px, bold);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
  }
}

@mixin link-2 {
  @include roboto(14px, 20px, bold);
  @include mixins.onep-small-and-down {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
}

@mixin link-3 {
  @include roboto(12px, 18px, bold);
  @include mixins.onep-small-and-down {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
}

@mixin button-mini {
  @include body-3-bold;
}

@mixin button-small {
  @include body-3-bold;
}

@mixin button {
  @include body-2-bold;
}

@mixin bold {
  font-weight: bold;
}

@mixin medium {
  font-weight: 500;
}

@mixin semi-bold {
  font-weight: 600;
}

@mixin italic {
  font-style: italic;
}

@mixin uppercase {
  text-transform: uppercase;
}

@mixin capitalize {
  text-transform: capitalize;
}
