@use '../../colors';
@use '../../variables';
@use '../../text';

@mixin style {
  onep-creation-card {
    @include text.heading-5();
    @include text.bold();
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: colors.grey('extra-light');
    border: 1px solid colors.grey('soft');
    box-sizing: border-box;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    .onep-creation-card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: colors.red('classic');
      border-radius: 50px;
      padding: 7px;
      margin-right: variables.$spacing;
      svg {
        font-size: 14px;
        color: colors.white();
      }
    }
  }
  onep-creation-card:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
  }
}
