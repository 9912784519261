@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../mixins';
@use '../../text';

@mixin state($state) {
  border-radius: variables.$spacing * 8;
  padding: 2px variables.$spacing;
  @if $state == new {
    border: 1px solid colors.blue('brand');
    color: colors.blue('brand');
    background-color: colors.blue('light');
  } @else if $state == accepted {
    border: 1px solid colors.green('dark');
    background-color: colors.green('light');
    color: colors.green('dark');
  } @else if $state == denied {
    border: 1px solid colors.orange('error');
    color: colors.orange('error');
    background-color: colors.orange('extra-light');
  } @else if $state == gradient {
    @include text.caption-1();
    -webkit-text-fill-color: colors.white();
  } @else {
    background-color: colors.grey('extra-light');
    border: 1px solid colors.grey('medium');
    color: colors.grey('medium');
  }
}

@mixin thumbnail-gradient($gradient) {
  color: white;
  @if $gradient == 'black' {
    @include colors.linear-gradient-black;
  } @else if $gradient == 'blue' {
    @include colors.linear-gradient-blue;
  } @else if $gradient == 'red' {
    @include colors.linear-gradient-red;
  } @else if $gradient == 'green' {
    @include colors.linear-gradient-green;
  } @else if $gradient == 'orange' {
    @include colors.linear-gradient-orange;
  }
}

@mixin thumbnail-background($background) {
  color: white;
  @if $background == 'red' {
    background-color: colors.red();
  }
}

@mixin thumbnail-border($border) {
  box-shadow: inherit;
  @if $border == 'red' {
    border: 1px solid colors.red();
  } @else if $border == 'grey' {
    border: 1px solid colors.grey('medium');
  }
}

@mixin style {
  onep-tag-badge {
    cursor: default;
    display: inline-block;
    margin-right: variables.$spacing;
    margin-bottom: variables.$spacing;
    text-align: center;
    background-color: colors.grey('extra-light');
    border: 1px solid colors.grey('light');
    padding: 2px variables.$spacing;
    border-radius: variables.$spacing * 8;
    @include text.caption-1;
    svg {
      cursor: pointer;
      font-size: variables.$font-size;
      margin-left: math.div(variables.$spacing, 2);
      color: colors.grey('medium');
    }
  }

  onep-tag-badge.onep-tag-badge-edit:hover {
    border-color: colors.grey('medium');
  }

  onep-thumbnail-badge {
    cursor: default;
    display: inline-block;
    background-color: colors.white();
    text-align: center;
    padding: 5px variables.$spacing * 2;
    border-radius: variables.$spacing * 8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    @include text.subheading-2;
    &.onep-thumbnail-badge-small {
      padding: 4px variables.$spacing * 1.5;
      @include text.body-4;
    }
    &.onep-thumbnail-badge-border {
      @include thumbnail-border('red');
    }
    &.onep-thumbnail-badge-border-red {
      @include thumbnail-border('red');
    }
    &.onep-thumbnail-badge-border-grey {
      @include thumbnail-border('grey');
    }
    &.onep-thumbnail-badge-background-red {
      @include thumbnail-background('red');
    }
    &.onep-thumbnail-badge-gradient-black {
      @include thumbnail-gradient('black');
    }
    &.onep-thumbnail-badge-gradient-blue {
      @include thumbnail-gradient('blue');
    }
    &.onep-thumbnail-badge-gradient-green {
      @include thumbnail-gradient('green');
    }
    &.onep-thumbnail-badge-gradient-orange {
      @include thumbnail-gradient('orange');
    }
    &.onep-thumbnail-badge-gradient-red {
      @include thumbnail-gradient('red');
    }
  }

  onep-state-badge {
    cursor: default;
    display: inline-block;
    text-align: center;
    @include text.caption-1;
    @include text.medium;
    .onep-state-badge-new {
      @include state(new);
    }
    .onep-state-badge-accepted {
      @include state(accepted);
    }
    .onep-state-badge-denied {
      @include state(denied);
    }
    .onep-state-badge-completed {
      @include state(completed);
    }
    .onep-state-badge-gradient {
      @include state(gradient);
    }
  }
}
