@use '../../text';
@use '../../variables';
@use '../../colors';

@mixin style {
  onep-datatable-line-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.grey('soft');
    > * {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
