@use '../../variables';
@use '../../colors';
@use 'field-variables';
@use '../../text';
@use '../../mixins';

@mixin checked($is-checked: true, $color) {
  @if $is-checked {
    background-color: $color;
    svg {
      color: colors.white();
    }
  } @else {
    background-color: transparent;
    &:hover {
      background-color: colors.grey('extra-light');
    }
    svg {
      color: $color;
    }
  }
}

@mixin style {
  onep-icon-toggle-button button {
    border: 1px solid colors.grey('light') !important;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    transition: all ease-in-out 300ms;
    cursor: pointer;
    position: relative;
    height: variables.$spacing * 4;
    width: variables.$spacing * 4;
    svg[onepIcon] {
      font-size: 24px;
    }
  }
  onep-icon-toggle-button {
    onep-radio-button {
      button {
        &.onep-checked::before,
        &.onep-checked::after,
        &.onep-not-checked::before {
          content: inherit;
        }
      }
    }
    onep-radio-button:first-child {
      button {
        border-radius: 4px 0 0 4px;
        border-right: 1px solid colors.grey('light');
      }
    }
    onep-radio-button:last-child {
      button {
        border-left: 0 !important;
        border-radius: 0 4px 4px 0;
      }
    }
    onep-radio-button {
      display: inline-block;
    }
    background-color: colors.white();
    display: inline-block;
    .onep-icon-toggle-button-success {
      .onep-not-checked {
        @include checked($is-checked: false, $color: colors.green('dark'));
      }
      .onep-checked {
        @include checked($is-checked: true, $color: colors.green('dark'));
      }
    }
    .onep-icon-toggle-button-error {
      .onep-not-checked {
        @include checked($is-checked: false, $color: colors.orange('error'));
      }
      .onep-checked {
        @include checked($is-checked: true, $color: colors.orange('error'));
      }
      &:hover {
        background-color: colors.grey('extra-light');
      }
    }

    &.onep-icon-toggle-button-disable {
      background-color: colors.grey('extra-light');
      button {
        cursor: default;
        pointer-events: none;
        svg {
          color: colors.grey('light') !important;
        }
      }
    }
  }
}
