@use 'sass:map';
@use '../../text';
@use '../../variables';
@use '../../colors';
@use '../../mixins';

@mixin style {
  onep-list {
    onep-list-item {
      display: flex;
      flex-direction: row;
      background-color: colors.white();
      border: 1px solid colors.grey('soft');
      border-radius: 4px;
      height: 72px;
      .onep-list-item-container {
        padding: variables.$spacing * 2 0 variables.$spacing * 2
          variables.$spacing * 2;
        flex: 1;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: space-between;
        @include text.body-2();
        onep-list-item-name {
          font-weight: 500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        onep-list-item-description {
          font-weight: 400;
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          onep-state-label {
            overflow: hidden;
            * {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          onep-state-label:before {
            content: '';
            border-left: 1px solid colors.grey('light');
            height: 80%;
            margin-left: variables.$spacing;
            margin-right: variables.$spacing;
          }
        }
      }
      onep-list-item-value {
        display: flex;
        margin-left: variables.$spacing;
        margin-right: variables.$spacing * 2;
        flex: 0 0 80px;
        justify-content: flex-end;
        align-items: center;
        @include text.heading-3();
        font-size: 16px;
      }
    }
  }
}
