@use 'sass:map';

$-white: #fff;
$-black: #000;

$-dark-red: #da1b1b;
$-classic-red: #e84545;
$-light-red: #ed6e6e;
$-contrast: false;

$-grey-shades: (
  'black': #080808,
  'light-black': #333,
  'dark': #666,
  'medium': #999,
  'light': #ccc,
  'soft': #e5e5e5,
  'extra-light': #f7f7f7
);

$-orange-shades: (
  'brand': #ef8c39,
  'error': #ea5b3a,
  'light': #ffbbac,
  'extra-light': #f8e1dc
);

$-blue-shades: (
  'contact': #0052cc,
  'brand': #3f99f7,
  'light': #e9faff
);

$-green-shades: (
  'brand': #57c58d,
  'dark': #14b588,
  'success': #22d5a3,
  'light': #e8f8f3
);

$-purple-shades: (
  'brand': #9c60e6
);

$-error-orange: #ea5b3a;
$-contact-blue: #0052cc;

$-steal-blue: #3c92af;
$-light-blue: #eef7fa;

@mixin configure(
  $dark-red: null,
  $classic-red: null,
  $light-red: null,
  $contrast: null
) {
  @if $dark-red {
    $-dark-red: $dark-red !global;
  }
  @if $classic-red {
    $-classic-red: $classic-red !global;
  }
  @if $light-red {
    $-light-red: $light-red !global;
  }
  @if $contrast {
    $-contrast: $contrast !global;
  }
}

@function contrast() {
  @return $-contrast;
}

@function white() {
  @return $-white;
}

@function black() {
  @return $-black;
}

/*
  $hex is set to add additional hex to the 6digits color
  It can be used for example to set opacity
*/
@function grey($shade, $hex: null) {
  @if (map.has-key($-grey-shades, $shade)) {
    @return #{map.get($-grey-shades, $shade)}#{$hex};
  } @else {
    @error 'Argument #{$shade} must be one of #{map.keys($-grey-shades)}!';
  }
}

@function red($shade: classic, $hex: null) {
  @if ($shade == classic) {
    @return #{$-classic-red}#{$hex};
  }
  @if ($shade == dark) {
    @return #{$-dark-red}#{$hex};
  }
  @if ($shade == light) {
    @return #{$-light-red}#{$hex};
  }
  @error 'Argument #{$shade} must be one of classic, dark or light!';
}

@function orange($shade, $hex: null) {
  @if (map.has-key($-orange-shades, $shade)) {
    @return #{map.get($-orange-shades, $shade)}#{$hex};
  } @else {
    @error 'Argument #{$shade} must be one of #{map.keys($-orange-shades)}!';
  }
}

@function green($shade, $hex: null) {
  @if (map.has-key($-green-shades, $shade)) {
    @return #{map.get($-green-shades, $shade)}#{$hex};
  } @else {
    @error 'Argument #{$shade} must be one of #{map.keys($-green-shades)}!';
  }
}

@function blue($shade, $hex: null) {
  @if (map.has-key($-blue-shades, $shade)) {
    @return #{map.get($-blue-shades, $shade)}#{$hex};
  } @else {
    @error 'Argument #{$shade} must be one of #{map.keys($-blue-shades)}!';
  }
}

@function purple($shade, $hex: null) {
  @if (map.has-key($-purple-shades, $shade)) {
    @return #{map.get($-purple-shades, $shade)}#{$hex};
  } @else {
    @error 'Argument #{$shade} must be one of #{map.keys($-purple-shades)}!';
  }
}

@function error-orange() {
  @warn "The `error-orange()` function is deprecated, use `orange($shade)` function instead";
  @return $-error-orange;
}

@function contact-blue() {
  @return $-contact-blue;
}

@mixin linear-gradient-red {
  background: -webkit-linear-gradient(90deg, #f0665f 0%, #be2424 100%);
}

@mixin linear-gradient-blue {
  background: -webkit-linear-gradient(90deg, #3f99f7 0%, #102fcd 100%);
}

@mixin linear-gradient-green {
  background: -webkit-linear-gradient(90deg, #57c58d 0%, #069b92 100%);
}

@mixin linear-gradient-black {
  background: -webkit-linear-gradient(90deg, #999999 0%, #333333 100%);
}

@mixin linear-gradient-orange {
  background: -webkit-linear-gradient(90deg, #ec3a62 0%, #ef685d 100%);
}
