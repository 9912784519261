@import '../../variables';
@import '../../colors';
@import '../../text';
@import '../../grid';

@mixin onep-home-article-hover-style {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.45) -8.3%,
    rgba(0, 0, 0, 0.09) 14.72%,
    rgba(0, 0, 0, 0.162) 52.01%,
    rgba(0, 0, 0, 0.72) 100%
  );
  border-radius: 4px;
  filter: drop-shadow(0px 14px 15px rgba(0, 0, 0, 0.25));
}

@mixin onep-home-article-gradient {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) -8.3%,
    rgba(0, 0, 0, 0.06) 14.72%,
    rgba(0, 0, 0, 0.108) 52.01%,
    rgba(0, 0, 0, 0.48) 100%
  );
  border-radius: 4px;
}

@mixin style {
  onep-home-article {
    box-sizing: border-box;
  }

  .mobile {
    .onep-home-article-layout {
      padding: 2 * $spacing 3 * $spacing 2 * $spacing 2 * $spacing;
    }
  }
  .onep-home-article-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 3 * $spacing;
    position: absolute;
    z-index: 2;
  }

  .onep-home-article-container::before {
    @include onep-home-article-gradient();
    z-index: 1;
  }

  .onep-home-article-description {
    margin-top: 2 * $spacing;
    @include body-1();
    color: white();
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .onep-home-article-container {
    width: 100%;
    position: relative;
    height: 50 * $spacing;
    cursor: pointer;
  }

  .onep-home-article-category {
    @include subheading-2();
    color: white();
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    width: calc(100% - 48px);
  }

  .onep-home-article-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .onep-home-article-content {
    max-width: 480px;

    .onep-home-article-secondary-info {
      @include body-3();
      color: white();
      margin-top: 3 * $spacing;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      width: 100%;
    }
  }

  .onep-home-article-illustration {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    z-index: 0;
  }

  //container
  .onep-home-article-container:hover:not(.mobile):before {
    @include onep-home-article-hover-style();
  }

  .onep-home-article-description {
    display: none;
  }

  .extra-large {
    .onep-home-article-title {
      -webkit-line-clamp: 2;
      @include source-serif-pro(32px, 38px, 700);
      color: white();
    }
    .onep-home-article-description {
      display: -webkit-box;
    }
  }

  .large {
    .onep-home-article-title {
      -webkit-line-clamp: 2;
      @include source-serif-pro(24px, 28px, 700);
      color: white();
    }
  }
  .medium {
    .onep-home-article-content {
      max-width: 394px;
    }

    .onep-home-article-title {
      -webkit-line-clamp: 3;
      @include source-serif-pro(24px, 28px, 700);
      color: white();
    }
  }

  .small {
    .onep-home-article-content {
      max-width: 272px;
    }

    .onep-home-article-title {
      -webkit-line-clamp: 3;
      @include source-serif-pro(20px, 28px, 700);
      color: white();
    }
  }

  .mobile {
    .onep-home-article-content {
      width: auto;
      word-break: break-word;
    }

    &.onep-home-article-container {
      height: 343px !important;
    }

    .onep-home-article-title {
      -webkit-line-clamp: 3;
      @include source-serif-pro(22px, 28px, 700);
      color: white();
    }

    .onep-home-article-description {
      margin-right: 32px;
    }
  }
}
