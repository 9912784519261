@use 'sass:map';
@use '../../text';
@use '../../variables';
@use '../../grid';
@use '../../colors';
@use '../../mixins';

@mixin style {
  onep-header,
  onep-header-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background-color: variables.$white;

    .onep-header-progress-bar {
      height: 2px;
      position: absolute;
      background-color: colors.red('classic');
      left: 0;
      bottom: 0;
      z-index: 1;
      transition: width 0.8s;
    }

    onep-header-stepper {
      > .onep-header-stepper-label {
        @include text.body-3();
        @include mixins.onep-small-and-down {
          @include text.caption-1();
          color: colors.grey('medium');
        }
        color: colors.grey('medium');
      }
      p {
        margin: 0;
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .onep-header-content {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      height: variables.$header-height;
      @include grid.onep-horizontal-tablet-and-down {
        height: variables.$header-mobile-height;
      }
    }

    .onep-header-divider {
      z-index: 10;
      top: variables.$header-height - 1px;
      @include grid.onep-horizontal-tablet-and-down {
        top: variables.$header-mobile-height - 1px;
      }
      height: 1px;
      width: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
      animation: fadeIn ease 800ms;
      -webkit-animation: fadeIn ease 800ms;
      -moz-animation: fadeIn ease 800ms;
      -o-animation: fadeIn ease 800ms;
      -ms-animation: fadeIn ease 800ms;
    }

    onep-header-left {
      float: left;
      width: 216px;
    }

    onep-header-center {
      margin-left: auto;
      margin-right: auto;
    }

    onep-header-right {
      float: right;
      width: 216px;
      .onep-header-close {
        float: right;
        font-size: 32px;
        margin-top: 16px;
        margin-right: 16px;
        @include mixins.onep-small-and-down {
          font-size: 24px;
          margin-top: 12px;
          margin-right: 12px;
        }
      }
    }
  }
}
