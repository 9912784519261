@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-feedback-banner {
    overflow: hidden;
    border-radius: 4px;
    /*
      3F set 25% opacity to 6digits colors
    */
    border: 1px solid colors.blue('brand', 3F);
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: colors.blue('light');
    padding: 12px 16px;
    .onep-feedback-banner-text {
      @include text.body-3();
    }
    svg {
      flex-basis: 20px;
      flex-shrink: 0;
      margin-right: variables.$spacing * 1.5;
      border-radius: 50px;
      background-color: colors.blue('brand');
      width: 20px;
      height: 20px;
      color: colors.white();
      font-size: 20px;
    }
  }
}
