@use 'sass:map';
@use 'sass:math';
@use '../../text';
@use '../../colors';
@use '../../variables';

@mixin style {
  onep-sub-header {
    display: flex;
    flex-direction: row;
    height: variables.$spacing * 6;
    background-color: colors.grey('extra-light');
    onep-sub-header-item {
      padding: 12px 0;
      cursor: pointer;
      width: 100%;
      .onep-sub-header-item-container {
        div {
          transition: all ease-in-out 250ms;
        }
        @include text.body-1;
        @include text.medium();
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        svg[onepIcon] {
          font-size: variables.$spacing * 3;
          color: colors.grey('medium');
          margin-right: math.div(variables.$spacing, 2);
        }
        .onep-sub-header-item-active {
          margin-left: math.div(variables.$spacing, 2);
          width: 6px;
          height: 6px;
          border-radius: 20px;
          background: colors.red('classic');
          margin-top: -(variables.$spacing);
        }
      }
    }
    onep-sub-header-item:not(:last-child) {
      .onep-sub-header-item-container {
        border-right: 1px solid colors.grey('light');
      }
    }
  }
}
