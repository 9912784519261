@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';
@use '../../grid';

@mixin style {
  onep-toast-card {
    display: flex;
    flex-direction: row;
    padding: variables.$spacing variables.$spacing (variables.$spacing * 1.5)
      (variables.$spacing * 2);
    @include grid.onep-large-mobile-and-up {
      padding: (variables.$spacing * 1.5) variables.$spacing
        (variables.$spacing * 2) (variables.$spacing * 2);
    }
    @include grid.onep-large-mobile-and-down {
      padding: (variables.$spacing * 1.5) variables.$spacing
        (variables.$spacing * 1.5) (variables.$spacing * 2);
    }
    background-color: colors.grey('extra-light');
    border: 1px solid colors.grey('light');
    border-radius: math.div(variables.$spacing, 2);
    align-items: center;
    onep-toast-card-body {
      @include grid.onep-large-mobile-and-up {
        @include text.body-1();
        margin-right: variables.$spacing * 3;
      }
      @include grid.onep-large-mobile-and-down {
        @include text.caption-1();
        margin-right: variables.$spacing * 2;
      }
    }
    .onep-toast-card-icon {
      color: colors.grey('medium');
      align-self: flex-start;
      cursor: pointer;
      flex: 0 0 24px;
      font-size: 24px;
      @include grid.onep-large-mobile-and-down {
        flex: 0 0 20px;
        font-size: 20px;
      }
    }
    .onep-toast-card-icon:hover {
      color: colors.grey('dark');
    }
    img {
      margin-top: math.div(variables.$spacing, 2);
      height: 72px;
      width: 72px;
      margin-right: variables.$spacing * 3;
      @include grid.onep-large-mobile-and-down {
        height: 48px;
        width: 48px;
        margin-right: variables.$spacing * 1.5;
      }
    }
  }
}
