@use '../../variables';
@use '../../text';
@use '../../colors';
@import '../../mixins';

$spacing: variables.$spacing;

@mixin style {
  onep-distribution-summary {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    onep-distribution-summary-section {
      width: 100%;
      display: flex;
      border-bottom: 1px solid colors.grey('light');
      flex-direction: column;
      padding-top: variables.$spacing * 3;
      padding-bottom: variables.$spacing * 3;

      onep-distribution-summary-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: variables.$spacing * 3;

        .onep-summary-amount-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          margin: 0;

          .free-amount {
            .icon {
              color: colors.grey('light-black');
            }
            .amount {
              font-weight: 500;
              color: colors.grey('dark');
              text-decoration-line: line-through;
              text-decoration-thickness: 2px;
            }
          }
        }

        p {
          margin: 0;
        }

        .onep-summary-item-description,
        .onep-summary-item-sub-description {
          margin: 0;
        }
      }
      :last-child {
        margin-bottom: 0;
      }
    }
    :first-child {
      padding-top: 0;
    }

    onep-distribution-summary-explanation {
      @include text.body-3;
      @include text.italic;
      color: colors.grey('dark');
    }

    onep-distribution-summary-total {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: variables.$spacing * 3;

      .onep-distribution-summary-total-sublabel {
        color: colors.grey('dark');
      }

      p {
        margin: 0;
      }
    }
  }
}
