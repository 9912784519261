@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use 'field-variables';
@use '../../text';
@use '../../mixins';

@mixin style {
  onep-checkbox {
    > label > div {
      align-self: flex-start;
    }
  }
  onep-checkbox > label {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  onep-checkbox > .onep-checkbox-text {
    flex-direction: column;
  }

  .onep-checkbox-large {
    .onep-checkbox-visual-content {
      margin-top: math.div(variables.$spacing, 1.5);
    }
    > label > div {
      > input {
        width: variables.$spacing * 3;
        height: variables.$spacing * 3;
      }
      > onep-checkbox-tick svg {
        font-size: variables.$font-size * 1.5;
      }
    }
    &.onep-checkbox-indeterminate svg {
      font-size: 200px;
    }
  }

  .onep-checkbox-primary-color {
    > label > div {
      > input {
        border-color: colors.grey('light');
        background-color: variables.$white !important;

        &:disabled {
          border-color: colors.grey('light');
          background-color: colors.grey('soft') !important;
        }
      }

      > onep-checkbox-tick svg {
        color: variables.$white;
      }
    }
    &.onep-checkbox-checked > label > div > input {
      border-color: colors.red('dark');
      background-color: colors.red('classic') !important;
    }
  }

  .onep-checkbox-ghost-color {
    > label > div {
      > input {
        border-color: colors.grey('light');
        background-color: variables.$white !important;

        &:disabled {
          border-color: colors.grey('light');
          background-color: variables.$white !important;
        }
      }

      > onep-checkbox-tick svg {
        color: colors.red('classic');
      }
    }
    &.onep-checkbox-checked > label > div > input {
      border-color: colors.grey('light');
    }
  }

  .onep-checkbox-native-input-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
  }

  .onep-checkbox-native-input {
    outline: none;
    transition: all ease-in-out 100ms;
    width: variables.$spacing * 2;
    height: variables.$spacing * 2;
    margin: math.div(variables.$spacing, 2);
    border-radius: 4px;
    border: 1px solid colors.grey('light');
    cursor: pointer;

    &:focus {
      border: 1px solid colors.grey('medium');
    }

    &:disabled {
      background-color: colors.grey('soft');
      border: 1px solid colors.grey('light');
      cursor: default;
    }
  }

  .onep-checkbox-visual-content {
    @include text.body-1;
    @include text.medium;
    display: flex;
    flex-direction: column;
    margin: 1px 0 math.div(variables.$spacing, 2)
      math.div(variables.$spacing, 2);
    justify-content: center;
    color: colors.grey('light-black');
  }

  .onep-checkbox-description {
    margin-left: math.div(variables.$spacing, 2);
    @include text.roboto(14px, 20px, 400);
    color: colors.grey('medium');
  }

  .onep-checkbox-checked,
  .onep-checkbox-disabled > label {
    cursor: default;
  }
  .onep-checkbox-checked .onep-checkbox-native-input {
    background-color: colors.green('success');
    border: 1px solid colors.green('dark');

    &:disabled {
      border: 1px solid colors.grey('light');
      background-color: colors.grey('soft');
    }
  }

  .onep-checkbox-disabled .onep-checkbox-visual-content {
    color: colors.grey('light');
    cursor: auto;
  }

  .onep-checkbox-indeterminate .onep-checkbox-native-input {
    border: 1px solid colors.grey('dark');
    background-color: colors.grey('medium');
  }

  .onep-checkbox-indeterminate onep-checkbox-tick svg {
    font-size: 20px;
  }

  onep-checkbox-tick {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .onep-checkbox-tick-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      font-size: variables.$font-size;
      color: variables.$white;
    }
  }

  .onep-single-checkbox {
    .onep-checkbox-native-input {
      border: 1px solid colors.grey('dark');
      background-color: variables.$white !important;
      width: variables.$spacing * 3;
      height: variables.$spacing * 3;

      &:focus {
        border-color: colors.grey('light');
      }

      &:disabled {
        border-color: colors.grey('light');
        background-color: colors.grey('soft') !important;
      }
    }
    onep-checkbox-tick svg {
      font-size: variables.$font-size * 1.5;
      color: colors.red('classic');
    }
    &.onep-checkbox-disabled {
      onep-checkbox-tick svg {
        color: colors.white();
      }
    }
  }
  .onep-single-checkbox.onep-checkbox-checked > label > div > input {
    border: 1px solid colors.grey('light');
  }

  .onep-single-checkbox.onep-checkbox-indeterminate svg {
    font-size: 200px;
  }
}
