@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../mixins';
@use '../../text';

@mixin medium {
  padding: variables.$spacing * 1.5 variables.$spacing * 2 variables.$spacing *
    1.5 variables.$spacing * 2;
  @include text.body-1;
}

@mixin large {
  padding: variables.$spacing * 3 variables.$spacing * 2 variables.$spacing * 3
    variables.$spacing * 3;
  @include text.heading-5;
  @include text.bold;
}

@mixin style {
  button[onep-card-button] {
    .onep-card-button-container {
      display: grid;
    }
    &.onep-card-button-medium {
      @include medium;
    }
    &.onep-card-button-large {
      @include large;
    }
    &.onep-card-button-disabled {
      color: colors.grey('medium');
      cursor: unset;
      border: 1px solid colors.grey('light');
      background-color: colors.grey('extra-light');

      &:active,
      &:hover,
      &:focus {
        border: 1px solid colors.grey('light');
        outline: none;
        svg {
          color: colors.grey('medium');
        }
      }
    }
    .onep-card-button-title {
      @include text.bold;
      margin-bottom: math.div(variables.$spacing, 2);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .onep-card-button-text-ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    transition: all ease-in-out 250ms;
    background: colors.white();
    border: 1px solid colors.grey('soft');
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    text-align: start;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    svg {
      transition: all ease-in-out 250ms;
      flex-shrink: 0;
      margin-left: 16px;
      color: colors.grey('medium');
      transform: rotate(-90deg);
      font-size: 24px;
      height: 24px;
      width: 24px;
    }

    &:active,
    &:hover,
    &:focus {
      transition: all ease-in-out 250ms;
      border: 1px solid colors.grey('light-black');
      outline: none;
      svg {
        transition: all ease-in-out 250ms;
        color: colors.grey('light-black');
      }
    }
  }
}
