$spacing: 8px;
$header-height: 80px;
$header-mobile-height: 56px;

$font-size: 14px;
$small-font-size: 12px;
$small-font-size-mobile: 10px;

$select-img-width: 16px;

/*
 * The use of the following color variables is deprecated.
 * Please use the colors functions from colors.scss instead.
 */
$white: #fff;
$black: #000;
$grey-shades: (
  'extraDark': #333,
  'dark': #666,
  'medium': #999,
  'light': #ccc,
  'veryLight': #e5e5e5,
  'extraLight': #f7f7f7
);
$secondary-colors: (
  'darkGreen': #14b588,
  'successGreen': #22d5a3,
  'errorOrange': #ea5b3a,
  'contactBlue': #0052cc
);
$red-shades: (
  'dark': #cd1515,
  'medium': #e12828,
  'light': #ee535f
);

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
