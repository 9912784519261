@use 'sass:math';
@use '../../colors';
@use '../../variables';
@use '../../text';
@use '../../mixins';

@mixin style {
  onep-copy-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid colors.grey('soft');
    border-radius: 4px;
    padding: variables.$spacing * 2 variables.$spacing * 8.5 variables.$spacing *
      2 variables.$spacing * 2;
    cursor: pointer;
    background-color: colors.grey('extra-light');
    overflow: hidden;
    @include text.body-1;
    &:hover {
      transition: all ease-in-out 300ms;
      border: 1px solid colors.grey('dark');
    }
    onep-copy-card-title {
      margin-bottom: math.div(variables.$spacing, 2);
      @include text.body-1;
      @include text.bold;
      @include mixins.onep-small-and-down {
        margin-bottom: variables.$spacing;
      }
    }
    > div {
      overflow-wrap: break-word;
    }

    svg[onepIcon] {
      position: absolute;
      color: colors.grey('dark');
      bottom: variables.$spacing * 2;
      right: variables.$spacing * 2;
      font-size: variables.$spacing * 3.5;
    }
  }
}
