@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-activation-card {
    display: flex;
    flex-direction: column;
    padding: (variables.$spacing * 3) (variables.$spacing * 2)
      (variables.$spacing * 2) (variables.$spacing * 3);
    background-color: colors.white();
    border: 1px solid colors.grey('soft');
    border-radius: math.div(variables.$spacing, 2);

    onep-switch {
      margin-top: variables.$spacing * 2;
      align-self: flex-end;
    }

    onep-activation-card-title {
      @include text.heading-5;
      font-weight: bold;
      margin-bottom: variables.$spacing;
    }

    onep-activation-card-body {
      min-height: 60px;
      @include text.body-3;
      color: colors.grey('medium');
      margin-bottom: variables.$spacing;
      margin-right: variables.$spacing * 2;
    }
  }
}
