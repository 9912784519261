@use 'sass:map';
@use 'sass:math';
@use '../../variables';
@use 'field-variables';
@use '../../colors';
@use './input';

@mixin style {
  onep-phone-field {
    @include input.input-border;
    display: flex;
    flex-direction: column;

    onep-field {
      .onep-field-label {
        z-index: 4;
      }

      onep-field-prefix {
        font-size: field-variables.$input-font-size;
        z-index: 4;
        position: absolute;
        bottom: 9px;
        left: 16px;
      }

      onep-select {
        border-radius: math.div(variables.$spacing, 2)
          math.div(variables.$spacing, 2) 0 0 !important;
        z-index: 2;
      }

      input {
        width: 100%;
        border-top-width: 0 !important;
        border-radius: 0 0 math.div(variables.$spacing, 2)
          math.div(variables.$spacing, 2) !important;

        &::placeholder {
          color: colors.grey('medium');
        }

        &:focus {
          z-index: 3;
          border-top-width: 0;
        }

        &.onep-field-mobile {
          onep-field-prefix {
            bottom: 8px;
          }
        }
      }

      .padded-input {
        padding-left: 46px !important;
        transition: padding-left ease-in-out 200ms;
      }
    }
    &.onep-input-error {
      input,
      onep-select {
        border: 1px solid map.get(variables.$secondary-colors, 'errorOrange');
        box-shadow: inset 0 0 0 1px
          map.get(variables.$secondary-colors, 'errorOrange');
        outline: none;
      }
    }
  }
}
