@mixin onep-large-and-up {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin onep-medium-only {
  @media (min-width: 414px) and (max-width: 1439px) {
    @content;
  }
}

@mixin onep-small-and-down {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin onep-small-only {
  @media (min-width: 414px) and (max-width: 1023px) {
    @content;
  }
}

@mixin onep-extra-small-and-down {
  @media (max-width: 413px) {
    @content;
  }
}

@mixin onep-medium-and-down {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin onep-medium-and-up {
  @media (min-width: 414px) {
    @content;
  }
}
