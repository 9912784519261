@use 'sass:map';
@use '../../variables';
@use '../../colors';
@use '../../text';

$tooltipColor: colors.grey('light-black');

$arrowMinMargin: 16px;
$tooltipBodyMargin: variables.$spacing;
$tooltipBodyPadding: variables.$spacing;

@mixin tooltipStyle($padding, $color) {
  .onep-tooltip {
    background-color: $color;
    padding: $padding;
  }
  .onep-tooltip-arrow {
    &.side-top {
      border-bottom-color: $color;
    }
    &.side-bottom {
      border-top-color: $color;
    }
    &.side-left {
      border-right-color: $color;
    }
    &.side-right {
      border-left-color: $color;
    }
  }
}

@mixin style {
  .onep-tooltip {
    position: relative;

    color: variables.$white;

    margin: $tooltipBodyMargin;

    border-radius: 4px;
    max-width: 184px;
    overflow-wrap: anywhere;
  }

  .onep-tooltip-default {
    @include text.caption-2;
    @include tooltipStyle($tooltipBodyPadding, colors.grey('black'));
  }

  .onep-tooltip-navigation-1 {
    @include text.body-2;
    @include tooltipStyle($tooltipBodyPadding * 1.5, colors.black());
  }

  .onep-tooltip-navigation-2 {
    @include text.body-3;
    @include tooltipStyle($tooltipBodyPadding * 1.5, colors.black());
  }

  .onep-tooltip-arrow {
    position: absolute;

    height: 0;
    width: 0;

    &.side-top {
      top: 1px;

      border-right: 7px solid transparent;
      border-bottom: 7px solid;
      border-left: 7px solid transparent;
    }

    &.side-bottom {
      bottom: 1px;

      border-right: 7px solid transparent;
      border-top: 7px solid;
      border-left: 7px solid transparent;
    }

    &.side-left {
      left: 1px;

      border-top: 7px solid transparent;
      border-right: 7px solid;
      border-bottom: 7px solid transparent;
    }

    &.side-right {
      right: 1px;

      border-top: 7px solid transparent;
      border-left: 7px solid;
      border-bottom: 7px solid transparent;
    }

    &.side-top,
    &.side-bottom {
      &.alignment-center {
        left: 50%;
        transform: translateX(-50%);
      }

      &.alignment-start {
        left: $arrowMinMargin;
      }

      &.alignment-end {
        right: $arrowMinMargin;
      }
    }

    &.side-left,
    &.side-right {
      &.alignment-center {
        top: 50%;
        transform: translateY(-50%);
      }

      &.alignment-start {
        top: $arrowMinMargin;
      }

      &.alignment-end {
        bottom: $arrowMinMargin;
      }
    }
  }
}
