@use 'sass:map';
@use 'sass:math';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../mixins';
@use './input';

@mixin style {
  .onep-field {
    onep-search-prefix {
      svg {
        font-size: 24px;
        color: colors.grey('medium');
      }
      onep-field-prefix {
        position: absolute;
        bottom: 2 * variables.$spacing;
        left: variables.$spacing * 2;
        @include mixins.onep-small-and-down {
          bottom: variables.$spacing + math.div(variables.$spacing, 2);
        }
      }
    }
  }
}
