@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin content-with-ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  height: variables.$spacing * 3;
  white-space: nowrap;
}

@mixin style {
  onep-action-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: (variables.$spacing * 3) (variables.$spacing * 4)
      (variables.$spacing * 3) (variables.$spacing * 3);
    background-color: colors.white();
    border: 1px solid colors.grey('soft');
    height: variables.$spacing * 19;
    border-radius: math.div(variables.$spacing, 2);
    box-sizing: border-box;
    &:hover {
      border-color: colors.grey('medium');
      .action-card-icon-container {
        svg[onepIcon] {
          display: block;
        }
      }
    }

    .action-card-icon-container {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 8px;
      right: 8px;

      svg[onepIcon] {
        display: none;
        cursor: pointer;
        font-size: 24px;
        width: 32px;
        height: 32px;
        color: colors.grey('medium');
        &:focus,
        &:hover {
          outline: none;
        }
        &:hover {
          color: colors.grey('dark');
        }

        &:not(:last-child) {
          margin-right: variables.$spacing;
        }
      }
    }

    onep-action-card-title {
      margin-bottom: variables.$spacing;
      @include text.heading-5;
      font-weight: bold;
      @include content-with-ellipsis;
    }

    onep-action-card-body {
      @include text.body-1;
      @include content-with-ellipsis;
      color: colors.grey('medium');
    }

    onep-action-card-sub-title {
      @include text.body-1;
      @include content-with-ellipsis;
    }
  }
}
