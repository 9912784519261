@use '../../variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';
@use '../../grid';

@mixin style {
  .onep-carousel-scroll-container {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  .onep-carousel-empty-element {
    flex: 0 0 100vw;
    background-color: transparent;
  }

  onep-carousel-item {
    display: flex;
    align-items: stretch;
    flex: 0 0 0;

    & > * {
      flex: 1;
    }
  }

  .onep-carousel-icon-container {
    display: flex;
    position: relative;
    margin-top: 32px !important;
    column-gap: 8px;

    .onep-carousel-icon-layout {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      color: white;
      cursor: pointer;
      background: white;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 3px;

      &:hover {
        background: colors.grey('extra-light');
      }

      &:disabled {
        background: colors.grey('soft');
      }

      .onep-carousel-icon {
        color: colors.grey('light-black');
        width: 24px;
        height: 24px;
        &.onep-carousel-icon-left {
          transform: rotate(90deg);
        }

        &.onep-carousel-icon-right {
          transform: rotate(270deg);
        }
      }
    }
  }

  @include mixins.onep-small-and-down {
    .onep-carousel-scroll-container {
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
