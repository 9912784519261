@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';

@mixin style {
  onep-option-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: variables.$spacing * 2;
    border-radius: math.div(variables.$spacing, 2);
    border: 1px solid colors.grey('light');
    position: relative;
    @include mixins.onep-small-and-down {
      padding-right: variables.$spacing * 3;
    }
    &.onep-option-selected {
      border: 1px solid colors.grey('light-black');
      outline: none;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 1px colors.grey('light-black');
      &:hover {
        border: 1px solid colors.grey('light-black');
        outline: none;
        box-sizing: border-box;
        box-shadow: inset 0 0 0 1px colors.grey('light-black');
      }
    }

    &.onep-option-disabled {
      border: 1px solid colors.grey('light');
      background-color: colors.grey('extra-light');
      cursor: not-allowed;
      pointer-events: none;

      onep-option-card-title {
        color: colors.grey('medium');
      }

      onep-option-card-content {
        color: colors.grey('medium');
      }

      onep-option-card-conditions {
        color: colors.grey('light');
      }
    }
    &:hover {
      box-shadow: 0 0;
      border: 1px solid colors.grey('medium');
    }

    onep-option-card-title {
      @include text.body-1;
      @include text.bold;
    }

    onep-option-card-content {
      margin-top: variables.$spacing;
      @include text.body-3;
    }

    onep-option-card-conditions {
      @include text.caption-1;
      @include text.bold;
      @include text.italic;
      color: colors.grey('medium');
      margin-top: variables.$spacing;
    }
  }
}
