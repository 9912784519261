@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../mixins';
@use '../../text';

@mixin style {
  onep-tabs {
    outline: none;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 0 math.div(variables.$spacing, 2);
    }
    li {
      @include text.heading-5;
      display: inline;
      cursor: pointer;
      padding-bottom: 2px;
      margin-right: variables.$spacing * 6;
      color: colors.grey('medium');
      &.active {
        transition: color ease-in-out 250ms;
        color: colors.grey('light-black');
        font-weight: bold;
        border-bottom: 2px solid colors.red('classic');
      }
    }
  }
  onep-tabs::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
