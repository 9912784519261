@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-select-card {
    & > * {
      border: 1px solid colors.grey('light');
      padding: 3 * variables.$spacing;
      display: flex;
      flex-direction: column;
      height: 272px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        border: 1px solid colors.grey('medium');
      }

      &:focus,
      &.selected {
        border: 1px solid colors.grey('light-black');
        box-shadow: inset 0 0 0 1px colors.grey('light-black');
      }

      onep-select-card-image > img {
        width: 192px;
        height: 92px;
        object-fit: cover;
        border-radius: 4px;
      }

      onep-select-card-title {
        @include text.heading-3;
        margin-top: 3 * variables.$spacing;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
      }

      onep-select-card-description {
        @include text.body-1;
        color: colors.grey('medium');
        margin-top: 2 * variables.$spacing;
        max-height: 72px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &:not(.with-description) {
      & > * {
        height: 72px;
        flex-direction: row;
        align-items: center;
        padding: 2 * variables.$spacing;
      }

      onep-select-card-title {
        margin: unset;
        text-align: center;
      }

      onep-select-card-image > img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 4px;
        margin-right: 16px;
      }

      onep-select-card-image + onep-select-card-title {
        text-align: left;
        width: unset;
      }

      onep-select-card-description {
        display: none;
      }
    }

    onep-select-card-image {
      display: flex;
    }
  }
}
