/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@use '@onep/ng-uikit-lib/styles/main' as uikit;
@use '@onep/icon-font-lib/svg/symbol/wedooicons';
@use '@onep/ng-uikit-lib/styles/colors';
@use '@onep/ng-uikit-lib/styles/text';
@import '@onep/ng-uikit-lib/styles/colors';
@include uikit.style;

:root {
  --standard-font: var(--description-font);
}

body {
  margin: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.underline {
  text-decoration: underline;
}

@each $grey in map.keys($-grey-shades) {
  .grey-#{$grey} {
    color: colors.grey($grey) !important;
  }
}

$sizes: 0, 4, 8, 12, 16, 24, 32, 36, 40, 44, 48, 56, 64, 72, 80, 96, 104, 105, 112, 120, 144, 160;

@each $size in $sizes {
  .mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .mt-#{$size} {
    margin-top: #{$size}px !important;
  }
  .mr-#{$size} {
    margin-right: #{$size}px !important;
  }
  .ml-#{$size} {
    margin-left: #{$size}px !important;
  }
  .m-#{$size} {
    margin: #{$size}px !important;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .pt-#{$size} {
    padding-top: #{$size}px !important;
  }
  .pr-#{$size} {
    padding-right: #{$size}px !important;
  }
  .pl-#{$size} {
    padding-left: #{$size}px !important;
  }
  .px-#{$size} {
    padding-left: #{$size}px !important;
    padding-right: #{$size}px !important;
  }
}

$weights: 400, 500, 700;

@each $weight in $weights {
  .fw-#{$weight} {
    font-weight: #{$weight};
  }
}
