@use 'sass:math';
@use '../../variables';
@use '../../text';
@use '../../colors';
@use '../../mixins';
@use '../../grid';

$spacing: variables.$spacing;

@mixin style {
  .onep-modal-backdrop {
    background-color: #000000;

    &.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 0.5;
    }
  }

  onep-side-sheet {
    position: relative;
    flex-direction: column;
    display: flex;
    flex: 1;
    width: 392px;
    height: 100vh;
    background-color: variables.$white;

    onep-header-modal {
      &.onep-header-modal-sidesheet {
        position: relative;
        width: 100%;
        height: 80px;
        @include mixins.onep-small-and-down {
          height: 56px;
        }

        .onep-header-content {
          transition: box-shadow 0.3s ease-in-out;
          box-shadow: 0 0 0 0;
        }

        .onep-header-content::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          box-shadow: 0px 20px 30px rgb(0 0 0 / 10%);
        }
      }
    }

    onep-side-sheet-scrollable-container {
      position: relative;
    }

    onep-side-sheet-bottom {
      position: fixed;
      width: 100%;
      bottom: 0;
      height: 160px;
      background-color: white;
      transition: box-shadow 0.3s ease-in-out;
      box-shadow: 0 0 0 0;
    }

    onep-side-sheet-bottom {
      &.onep-side-sheet-bottom-overflow::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0px -20px 30px rgb(0 0 0 / 10%);
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
      }

      &.onep-side-sheet-bottom-end-content::after {
        opacity: 0;
      }
    }

    @include mixins.onep-small-and-down {
      width: 248px;
    }

    .onep-side-sheet-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      @include mixins.onep-small-and-down {
        padding: 0;
      }

      onep-side-sheet-scrollable-container {
        display: flex;
        height: 100%;
        width: 100%;
        overflow: auto;
        flex-direction: column;

        onep-side-sheet-scrollable-content {
          position: relative;
          width: 100%;

          .onep-side-content {
            padding: 40px 18px 40px 32px;
            @include mixins.onep-small-and-down {
              padding: 0;
            }
          }
        }
      }
    }

    .onep-side-sheet-close {
      z-index: 1001;
      font-size: variables.$small-font-size * 2;
      position: absolute;
      right: variables.$spacing * 2;
      top: variables.$spacing * 2;
      color: colors.grey('medium');
      cursor: pointer;
      @include mixins.onep-small-and-down {
        right: variables.$spacing * 1.5;
        top: variables.$spacing * 2;
      }
    }

    &.onep-side-sheet-scrolling {
      onep-header-modal {
        .onep-header-content::after {
          opacity: 1;
        }
      }
    }
  }

  onep-maxi-modal {
    position: relative;
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    background-color: variables.$white;

    .onep-maxi-modal-container {
      width: 100%;

      @include grid.onep-vertical-tablet-and-down {
        onep-accordion-group .onep-accordion-title {
          padding: 16px 0;
        }
      }
    }

    .onep-maxi-modal-close-text {
      @include text.heading-5();
      z-index: 1001;
      position: absolute;
      right: variables.$spacing * 3;
      top: variables.$spacing * 3.5;
      @include mixins.onep-small-and-down {
        top: variables.$spacing * 2;
      }

      color: colors.grey('medium');
      cursor: pointer;
      margin: 0;
    }

    .onep-maxi-modal-close {
      z-index: 1001;
      font-size: variables.$spacing * 4;
      position: absolute;
      color: colors.grey('medium');
      cursor: pointer;
      right: variables.$spacing * 1.5;
      top: variables.$spacing * 3.5;
      @include mixins.onep-small-and-down {
        font-size: variables.$spacing * 3.5;
        top: variables.$spacing * 2;
      }
    }

    onep-header-modal {
      position: relative;
      display: flex;
    }
  }

  onep-pop-in-modal {
    border-radius: math.div(variables.$spacing, 2);
    position: relative;
    display: flex;
    flex: 1;
    background-color: variables.$white;
    width: 432px;
    margin-right: variables.$spacing * 3;
    margin-left: variables.$spacing * 3;

    @include mixins.onep-extra-small-and-down {
      width: calc(100% - #{$spacing} * 6);
    }

    .onep-pop-in-modal-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: auto;
      padding: variables.$spacing * 3 variables.$spacing * 4 variables.$spacing *
        4 variables.$spacing * 4;

      onep-pop-in-modal-title {
        display: block;
        margin-top: variables.$spacing * 3;
        margin-bottom: variables.$spacing * 2;
        margin-right: variables.$spacing * 2;
      }

      onep-pop-in-modal-content {
        @include text.body-1;
        display: block;
        margin-top: variables.$spacing * 2;
        margin-right: variables.$spacing * 2;
      }

      onep-pop-in-modal-buttons {
        button {
          @include mixins.onep-extra-small-and-down {
            flex: 1;
          }
        }

        flex-wrap: wrap-reverse;
        display: flex;
        justify-content: flex-end;
        margin-top: variables.$spacing * 3;
        @include mixins.onep-medium-and-up {
          > * {
            margin-right: variables.$spacing * 3;
          }

          > *:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .onep-pop-in-modal-close {
      font-size: variables.$small-font-size * 2;
      position: absolute;
      right: variables.$spacing;
      top: variables.$spacing;
      color: colors.grey('medium');
      cursor: pointer;
    }
  }
  onep-bottom-modal {
    border-radius: variables.$spacing * 2 variables.$spacing * 2 0 0;
    position: relative;
    display: flex;
    width: 100vw;
    background-color: variables.$white;

    .onep-bottom-modal-container {
      padding: variables.$spacing * 3 variables.$spacing * 2 variables.$spacing
        variables.$spacing * 2;
      width: 100%;
    }
  }

  .onep-side-sheet-component {
    overflow: hidden;
    padding-bottom: 241px;
    @include mixins.onep-small-and-down {
      padding-bottom: 216px;
    }
  }

  onep-side-sheet-v2 {
    position: relative;
    flex-direction: column;
    display: flex;
    flex: 1;
    width: 392px;
    height: 100vh;
    background-color: variables.$white;
    @include mixins.onep-small-and-down {
      width: 248px;
    }

    .onep-side-sheet-outlet {
      flex-grow: 1;
      overflow: hidden;
    }

    .onep-side-sheet-outlet > * {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    &.onep-side-sheet-scrolling {
      onep-header-modal {
        &.onep-header-modal-sidesheet {
          .onep-header-content::after {
            opacity: 1;
          }
        }
      }
    }

    onep-header-modal {
      &.onep-header-modal-sidesheet {
        position: relative;

        .onep-header-content {
          transition: box-shadow 0.3s ease-in-out;
          box-shadow: 0 0 0 0;
        }

        .onep-header-content::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          box-shadow: 0px 20px 30px rgb(0 0 0 / 10%);
        }

        onep-header-right {
          .onep-header-close {
            color: colors.grey('medium');
            transition: 0.3s;
          }
          .onep-header-close:hover {
            cursor: pointer;
            color: colors.grey('dark');
          }
        }
      }
    }

    > .ng-star-inserted {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      onep-side-sheet-v2-scrollable-container {
        display: flex;
        overflow-y: scroll;
        flex: 1;
        flex-direction: column;
        padding: 0 32px 0 32px;

        .onep-side-content {
          padding-bottom: 40px;
        }

        @include mixins.onep-small-and-down {
          padding: 0;
          .onep-side-content {
            padding: 0px;
          }
        }
      }

      onep-side-sheet-v2-bottom {
        position: relative;
        background-color: white;
        transition: box-shadow 0.3s ease-in-out;
        box-shadow: 0 0 0 0;
        display: flex;
        flex-direction: column;
        padding: 24px 32px;
        overflow: initial;
        min-height: 168px;
        box-sizing: border-box;

        &.onep-side-sheet-bottom-overflow::before {
          content: '';
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0px -20px 30px rgb(0 0 0 / 10%);
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }

        &.onep-side-sheet-bottom-end-content::before {
          opacity: 0;
        }
      }
    }
  }

  onep-loading-modal {
    border-radius: math.div(variables.$spacing, 2);
    position: relative;
    display: flex;
    flex: 1;
    background-color: variables.$white;
    width: 432px;
    margin-right: variables.$spacing * 3;
    margin-left: variables.$spacing * 3;

    @include mixins.onep-extra-small-and-down {
      width: calc(100% - #{$spacing} * 6);
    }

    .onep-loading-modal-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: auto;
      padding: variables.$spacing * 3 variables.$spacing * 4 variables.$spacing *
        4 variables.$spacing * 4;

      .onep-loading-modal-icon {
        display: flex;
        justify-content: center;

        onep-spinner .onep-spinner {
          margin-top: variables.$spacing;
          height: 6 * variables.$spacing;
          width: 6 * variables.$spacing;
          position: relative;
        }

        #done-icon {
          font-size: 72px;
        }
      }

      .onep-loading-modal-content {
        margin: 0 variables.$spacing;
      }

      .onep-loading-modal-content-title {
        text-align: center;
      }

      .onep-loading-modal-content-title-loading {
        margin-top: 36px;
      }

      .onep-loading-modal-content-title-finished {
        margin-top: 3 * variables.$spacing;
      }

      .onep-loading-modal-content-description {
        margin-top: variables.$spacing;
        text-align: center;

        @include text.body-1;
        color: colors.grey('medium');
      }

      .onep-loading-modal-button {
        margin-top: 4 * variables.$spacing;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
