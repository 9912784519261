@use '../../colors';
@use '../../text';

@mixin style {
  .onep-card-tag-fold {
    display: inline-block;
    height: 0;
    width: 0;
    border-right: 4px solid transparent;
    margin-top: 27px;
    right: 0;
  }

  .onep-card-tag-container {
    position: relative;
    onep-card-tag {
      right: -4px;
      top: 16px;
    }
  }

  onep-card-tag {
    align-self: flex-end;
    display: flex;
    position: absolute;
    flex-basis: content;
    align-items: center;
    justify-content: center;
    height: 24px;
    border-radius: 50px 0 0 50px;
    @include text.caption-1();
    p {
      font-size: 12px;
      line-height: 18px;
      margin: 0 5px 0 8px;
    }
    &.onep-card-tag-secondary {
      background-color: colors.grey('extra-light');
      box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.13);
      p {
        @include text.bold();
        color: colors.red('classic');
      }
      .onep-card-tag-fold {
        border-top: 3px solid colors.grey('light');
      }
    }
    &.onep-card-tag-primary {
      background: linear-gradient(96.54deg, #ec3a62 9.52%, #ef685d 97.29%);
      p {
        color: colors.white();
      }
      .onep-card-tag-fold {
        border-top: 3px solid #b22f26;
      }
    }
  }
}
