@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin icon {
  transition: all ease-in-out 250ms;
  font-size: 20px;
  margin: variables.$spacing;
}

@mixin style {
  onep-expansion-panel {
    display: block;
    border-radius: 4px;
    border: 1px solid colors.grey('soft');
    background-color: transparent;

    .onep-expansion-panel-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: (variables.$spacing * 2) 0 (variables.$spacing * 2)
        (variables.$spacing * 2);
      cursor: pointer;

      svg {
        @include icon;

        &.is-opened {
          transform: rotate(180deg);
        }
      }
    }

    .onep-expansion-panel-content {
      overflow: hidden;
    }

    onep-expansion-panel-content > * {
      padding: variables.$spacing * 2;
    }
  }
}
