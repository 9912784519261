@use 'sass:map';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../text';
@use './input';

@mixin style {
  onep-info-field-suffix onep-field-suffix {
    cursor: pointer;
    svg {
      color: colors.grey('medium');
      font-size: field-variables.$icon-size;
    }
  }
}
