@use 'sass:map';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../mixins';
@use './input';

@mixin style {
  .onep-field {
    onep-amount-prefix {
      line-height: 20px;
      z-index: 4;
      position: absolute;
      bottom: variables.$spacing * 2;
      left: variables.$spacing * 2;
      @include mixins.onep-small-and-down {
        bottom: variables.$spacing * 2 - 2;
      }
      &.onep-prefix-disabled {
        onep-field-prefix {
          color: colors.grey('medium');
        }
      }
    }
  }
  .onep-amount-input {
    display: flex;
    padding-left: variables.$spacing * 3 + 4 !important;
    @include mixins.onep-small-and-down {
      padding-left: variables.$spacing * 3 + 4 !important;
    }
    &.onep-input-error {
      input {
        border: 1px solid map.get(variables.$secondary-colors, 'errorOrange');
        box-shadow: inset 0 0 0 1px
          map.get(variables.$secondary-colors, 'errorOrange');
        outline: none;
      }
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
