@use 'sass:math';
@use '../../text';
@use '../../variables';
@use '../../colors';

$table-border-radius: 4px;

$table-bgcolor: variables.$white;
$table-border-width: 1px;
$table-border-color: colors.grey('light');

$row-hover-bg-color: colors.grey('soft');
$row-height: 48px;

$header-cell-bgcolor: colors.grey('light');
$header-cell-horizontal-padding: variables.$spacing * 2;
$header-cell-border-padding: $header-cell-horizontal-padding +
  variables.$spacing;
$header-height: 56px;

$cell-horizontal-padding: variables.$spacing * 2;
$cell-border-padding: $header-cell-horizontal-padding + variables.$spacing;

$footer-height: 48px;
$footer-horizontal-padding: variables.$spacing * 2;
$footer-spacing: variables.$spacing * 2;

$loading-min-height: 200px;

$selection-column-width: 32px;
$selection-column-full-width: $selection-column-width +
  $header-cell-border-padding + $header-cell-horizontal-padding;

@mixin style {
  onep-datatable {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    position: relative;

    border: $table-border-width solid $table-border-color;
    border-top-left-radius: $table-border-radius;
    border-top-right-radius: $table-border-radius;
  }

  .onep-cdk-table {
    width: 100%;

    flex: 1;
    border-spacing: 0;

    background-color: $table-bgcolor;

    border-top-left-radius: $table-border-radius;
    border-top-right-radius: $table-border-radius;

    thead {
      background-color: $header-cell-bgcolor;
    }

    th {
      @include text.body-1;
      font-weight: 500;

      height: $header-height;
      padding: 0 $header-cell-horizontal-padding;

      text-align: left;

      &:first-child {
        border-top-left-radius: math.div($table-border-radius, 2);
        padding-left: $header-cell-border-padding;
      }

      &:last-child {
        border-top-right-radius: math.div($table-border-radius, 2);
        padding-right: $header-cell-border-padding;
      }
    }

    td {
      @include text.body-3;
      font-weight: 400;

      height: $row-height;
      padding: 0 $cell-horizontal-padding;
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        padding-left: $cell-border-padding;
      }

      &:last-child {
        padding-right: $cell-border-padding;
      }
    }

    tbody tr {
      &:hover {
        background-color: $row-hover-bg-color;
      }
    }
  }

  .onep-cdk-table-custom-content {
    padding: $cell-horizontal-padding;
  }

  .onep-cdk-table-loading-content {
    flex: 0 0 auto;
    min-height: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include text.body-1;
  }

  .onep-datatable-footer {
    flex: 0 0 $footer-height;

    border-top: $table-border-width solid $table-border-color;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding: 0 $footer-horizontal-padding;

    margin-top: variables.$spacing * 3;

    onep-table-row-control {
      margin-right: $footer-spacing;
    }
  }
}
