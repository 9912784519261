@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use 'field-variables';
@use '../../text';
@use '../../mixins';

@mixin style {
  .onep-switch-container {
    display: flex;
    flex-direction: row;
    .onep-switch-text {
      font-family: var(--description-font);
      font-size: variables.$font-size;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: right;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: variables.$spacing;
      color: colors.grey('light-black');
    }
    .onep-switch-label-disabled {
      color: colors.grey('light') !important;
    }

    .onep-switch-native-input-container {
      .onep-switch-knob {
        height: 18px;
        width: 18px;
        border-radius: 100px;
        background-color: colors.white();
        position: absolute;
        pointer-events: none;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
        transition: left 0.2s ease-in-out, right 0.2s ease-in-out;
        &.onep-switch-on {
          left: 21px;
        }
        &:not(.onep-switch-on) {
          left: 1px;
        }
        &.onep-switch-disabled {
          background-color: colors.grey('extra-light');
        }
      }
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
    }

    .onep-switch-native-input {
      margin: 0;
      outline: none;
      transition: all ease-in-out 100ms;
      width: 40px;
      height: 20px;
      border-radius: 100px;
      cursor: pointer;
      background-color: colors.grey('light');

      &:disabled {
        background-color: colors.grey('soft');
        cursor: default;
      }
    }

    .onep-switch-visual-content {
      @include text.body-1;
      @include text.medium;
      display: flex;
      flex-direction: column;
      margin: 1px 0 math.div(variables.$spacing, 2)
        math.div(variables.$spacing, 2);
      justify-content: center;
      color: colors.grey('light-black');
    }

    .onep-switch-checked,
    .onep-switch-disabled > label {
      cursor: default;
    }

    .onep-switch-disabled .onep-switch-visual-content {
      color: colors.grey('light');
      cursor: auto;
    }
  }
  .onep-switch-checked .onep-switch-native-input {
    background-color: colors.red('classic');

    &:disabled {
      background-color: colors.grey('soft');
    }
  }
}
