@use 'sass:map';
@use 'sass:math';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../text';
@use './input';
@use '../../mixins';

@mixin style {
  .onep-field {
    @include input.input-border;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    flex: 1;

    .padded-input {
      padding-left: 46px !important;
      transition: padding-left ease-in-out 200ms;
    }

    .padded-amount-input {
      padding-left: 32px !important;
      transition: padding-left ease-in-out 200ms;
    }

    > div {
      display: flex;
    }

    &.with-suffix input {
      padding-right: variables.$spacing * 4 + 3px;
    }

    &.onep-field-mobile {
      .onep-select {
        .selected-display {
          padding: (variables.$spacing * 3) - 1 variables.$spacing * 2
            variables.$spacing - 1 variables.$spacing * 2;
          font-size: variables.$font-size;
        }
      }

      .onep-input {
        padding: (variables.$spacing * 3) - 2 variables.$spacing * 2
          variables.$spacing - 2 variables.$spacing * 2;
        font-size: variables.$font-size;
      }

      onep-field-prefix {
        font-size: variables.$font-size;
        svg {
          font-size: variables.$font-size * 2 - 4;
        }
      }

      .onep-field-label {
        font-size: variables.$font-size;
        &.active {
          font-size: variables.$small-font-size-mobile;
        }
      }

      .padded-input {
        padding-left: 42px !important;
      }
    }

    &.onep-field-small {
      onep-field-prefix {
        svg {
          font-size: 24px;
          color: colors.grey('medium');
        }

        position: absolute;
        top: variables.$spacing;
        left: variables.$spacing;
      }
      .onep-input {
        padding: variables.$spacing * 1.5 - 2px variables.$spacing * 1.5
          variables.$spacing * 1.5 - 2px variables.$spacing * 5 !important;

        border: 1px solid colors.grey('light');
        border-radius: math.div(variables.$spacing, 2);
        background-color: variables.$white;

        flex: 1;
        color: colors.grey('light-black');
        font-size: variables.$font-size;
        line-height: 16px;

        outline: none;

        &:focus {
          border: 1px solid colors.grey('light-black');
          box-shadow: 0 0 0 1px colors.grey('light-black');
        }

        &:disabled {
          background-color: colors.grey('extra-light');
        }

        &.onep-input-error {
          border: 1px solid map.get(variables.$secondary-colors, 'errorOrange');
          box-shadow: inset 0 0 0 1px
            map.get(variables.$secondary-colors, 'errorOrange');
          outline: none;
        }
      }
      onep-field-suffix {
        top: variables.$spacing;
      }
    }

    &.onep-field-no-floating-label {
      div {
        flex-direction: column;
        .onep-input {
          @include mixins.onep-small-and-down {
            padding: variables.$spacing + 5 variables.$spacing * 2;
          }
          padding: variables.$spacing * 2 - 1 variables.$spacing * 2;
          &::-webkit-input-placeholder {
            @include text.body-2;
            color: colors.grey('medium');
          }
        }
        onep-field-suffix {
          @include mixins.onep-small-and-down {
            top: variables.$spacing * 5.5;
          }
          top: variables.$spacing * 6;
        }
      }
    }
  }
}
