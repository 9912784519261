@use 'sass:map';
@use 'sass:math';
@use '../../text';
@use '../../variables';
@use '../../mixins';
@use '../../colors';
@use '../../grid';

@mixin style {
  onep-horizontal-scrollable-container {
    position: relative;
    display: flex;
    align-items: center;
    .onep-horizontal-scrollable-border-right {
      position: absolute;
      right: -16px;
      transition: all ease-in-out 300ms;
      width: 15px;
      height: 92px;
      z-index: 100;
      -webkit-box-shadow: -6px 0px 6px -6px #000000;
      box-shadow: -6px 0px 6px -6px #000000;
      @include mixins.onep-small-and-down {
        display: none;
      }
    }
    .onep-horizontal-scrollable-border-left {
      position: absolute;
      left: -16px;
      transition: all ease-in-out 300ms;
      width: 15px;
      height: 92px;
      z-index: 100;
      -webkit-box-shadow: 6px 0px 6px -6px #000000;
      box-shadow: 6px 0px 6px -6px #000000;
      @include mixins.onep-small-and-down {
        display: none;
      }
    }
    .onep-horizontal-scrollable-container-container {
      .onep-horizontal-scrollable-container-text {
        display: flex;
        width: 100%;
        word-wrap: break-word;
        onep-horizontal-scrollable-item {
          &:first-child {
            @include grid.onep-vertical-tablet-only {
              margin-right: variables.$spacing;
            }
            @include mixins.onep-extra-small-and-down {
              margin-right: variables.$spacing - 2;
            }
            margin-right: variables.$spacing + math.div(variables.$spacing, 2);
          }
          &:not(:first-child) {
            margin: 0 variables.$spacing + math.div(variables.$spacing, 2);
            @include grid.onep-vertical-tablet-only {
              margin: 0 variables.$spacing;
            }
            @include mixins.onep-extra-small-and-down {
              margin: 0 variables.$spacing - 2;
            }
          }
        }
      }
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      @include mixins.onep-small-and-down {
        overflow-x: scroll;
      }
      white-space: nowrap;
      scroll-behavior: smooth;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .onep-horizontal-scrollable-container-icon {
      position: absolute;
      margin-right: variables.$spacing;
      margin-left: variables.$spacing;
      border-radius: 50px;
      font-size: 16px;
      height: variables.$spacing * 2.5;
      width: variables.$spacing * 2.5;
      padding: math.div(variables.$spacing, 2);
      background: rgba(0, 0, 0, 0.7);
      color: colors.white();
      cursor: pointer;
      align-self: center;
      border: 1px solid colors.white();
      @include mixins.onep-small-and-down {
        display: none;
      }
    }
    .onep-horizontal-scrollable-container-icon.icon-right {
      right: 0;
      transform: rotate(270deg);
      z-index: 100;
    }
    .onep-horizontal-scrollable-container-icon.icon-left {
      transform: rotate(90deg);
      z-index: 100;
    }
  }
}
