@use 'sass:map';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../mixins';
@use './input';

@mixin style {
  onep-form-error-banner {
    onep-error-banner {
      > div {
        opacity: 0;
        transition: all ease-in-out 200ms;
      }
      height: 0;
      margin-bottom: 0;
      margin-top: 0;
      transition: all ease-in-out 200ms;
      .onep-error-banner-text {
        height: 0;
        transition: all ease-in-out 200ms;
      }
      &.onep-banner-error-display {
        > div {
          opacity: 1;
          transition: all ease-in-out 200ms;
        }
        height: 100%;
        display: flex;
        margin-bottom: variables.$spacing * 3;
        margin-top: variables.$spacing * 4;
        @include mixins.onep-small-and-down {
          margin-top: variables.$spacing * 5;
        }
        transition: all ease-in-out 200ms;
        .onep-error-banner-text {
          height: 100%;
          transition: all ease-in-out 200ms;
        }
      }
    }
  }
}
