@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';
@use '../../grid';

@mixin style {
  onep-service-card,
  onep-metrics-card,
  onep-new-related-card,
  onep-related-content-banner {
    position: relative;
    height: 186px;
    @include mixins.onep-small-and-down {
      height: 180px;
    }
    onep-card-tag {
      right: -5px;
      top: 12px;
    }

    .onep-service-card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      onep-service-card-title,
      onep-new-related-card-title {
        @include colors.linear-gradient-orange();
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      onep-metrics-card-title {
        color: colors.grey('medium') !important;
        margin-bottom: variables.$spacing * 2 !important;
      }
      onep-service-card-title,
      onep-metrics-card-title,
      onep-new-related-card-title {
        display: flex;
        flex-direction: row;
        margin-right: variables.$spacing * 3;
        @include text.body-4();
        margin-bottom: variables.$spacing * 3 + 4;
        max-width: 188px;
        align-content: center;
        align-items: baseline;
        onep-state-badge {
          .onep-state-badge-gradient {
            @include colors.linear-gradient-red();
          }
          margin-left: variables.$spacing;
        }
        @include mixins.onep-small-and-down {
          margin-bottom: variables.$spacing;
        }
      }

      img {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 84px;
        &.onep-service-card-img-background {
          width: 60px;
        }
        &.onep-new-related-card-img-background {
          width: 60px;
        }
      }

      onep-service-card-cta,
      onep-metrics-card-metric,
      onep-new-related-card-cta,
      onep-related-content-banner-title {
        margin-right: variables.$spacing * 3;
        display: flex;
        flex-direction: row;
        margin-bottom: variables.$spacing;
      }

      onep-service-card-cta,
      onep-new-related-card-cta,
      onep-related-content-banner-title {
        @include text.heading-5();
        font-weight: 700;
      }

      onep-metrics-card-metric {
        @include text.source-serif-pro(30px, 32px, 700);
        margin-bottom: variables.$spacing * 3;
      }

      onep-service-card-body,
      onep-metrics-card-body,
      onep-new-related-card-body {
        margin-right: variables.$spacing * 3;
        margin-bottom: variables.$spacing * 3;
        height: 60px;
        @include mixins.onep-small-and-down {
          margin-bottom: variables.$spacing * 1.5 + 2px;
          height: 66px;
        }
      }

      onep-service-card-bottom-label,
      onep-metrics-card-bottom {
        position: absolute;
        bottom: variables.$spacing * 3;
        align-self: flex-end;
        display: flex;
        @include text.heading-3();
        margin-right: variables.$spacing;
        @include mixins.onep-small-and-down {
          @include text.source-serif-pro(16px, 18px, 700);
        }
      }

      onep-metrics-card-bottom {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: -(variables.$spacing) * 3;
        button {
          margin-left: variables.$spacing * 3;
          margin-right: variables.$spacing * 3;
        }
        onep-metrics-card-description {
          margin-left: variables.$spacing * 3;
          @include text.body-3();
          color: colors.grey('medium');
        }
      }
    }

    onep-service-card-button {
      @include text.link-2();
      position: absolute;
      bottom: 16px;
      display: flex;
      align-self: flex-end;
      align-items: center;
      margin-right: variables.$spacing;
    }

    .onep-service-card-cta-caret {
      transform: rotate(270deg);
      color: colors.red('classic');
      font-size: 20px;
    }

    @include text.body-3();
    color: colors.grey('medium');
    @include mixins.onep-small-and-down {
      @include text.body-1();
      color: colors.grey('medium');
    }
    box-shadow: none;
    transition: box-shadow 200ms;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: (variables.$spacing * 2) variables.$spacing
      (variables.$spacing * 2) (variables.$spacing * 3);
    background-color: colors.white();
    border: 1px solid colors.grey('soft');
    border-radius: math.div(variables.$spacing, 2);
    overflow: hidden;
  }

  onep-metrics-card {
    height: 162px;
    padding: (variables.$spacing * 3) (variables.$spacing * 3)
      (variables.$spacing * 3) (variables.$spacing * 3);
  }

  onep-new-related-card {
    height: 134px;
    padding: (variables.$spacing * 3) (variables.$spacing * 2)
      (variables.$spacing * 3) (variables.$spacing * 2);
  }

  onep-service-card:hover,
  onep-metrics-card:hover,
  onep-new-related-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 200ms;
  }

  .onep-service-card-small {
    padding: variables.$spacing * 3;
    height: 100px;
    .onep-service-card-content {
      flex: 1;
      onep-service-card-title,
      onep-new-related-card-title {
        max-width: 108px;
      }
      onep-service-card-cta {
        margin: 0;
      }
    }
  }

  .onep-service-card-empty {
    cursor: default;
    .onep-service-card-content {
      justify-content: flex-start;
      img {
        position: relative;
        width: 40px;
        margin-bottom: variables.$spacing;
      }

      onep-service-card-body {
        margin-bottom: variables.$spacing !important;
      }
    }
  }
  .onep-service-card-empty:hover {
    box-shadow: none;
  }

  onep-related-content-banner {
    padding: (variables.$spacing * 3) variables.$spacing
      (variables.$spacing * 3) (variables.$spacing * 3);
    padding-right: 16 * variables.$spacing;
    height: unset;
    background-color: colors.grey('extra-light');
    &:hover {
      cursor: unset;
    }
    img {
      width: 100px !important;
      @include grid.onep-large-mobile-and-down {
        width: 72px !important;
      }
    }

    onep-related-content-banner-title {
      @include grid.onep-large-mobile-and-down {
        padding-right: 3 * variables.$spacing;
      }
    }

    @include grid.onep-horizontal-tablet-and-down {
      padding-right: 10 * variables.$spacing;
    }
    @include grid.onep-small-mobile-and-down {
      padding-right: 4 * variables.$spacing;
    }
    onep-related-content-banner-body {
      @include text.body-1();
      color: colors.grey('medium');
    }
  }

  onep-related-content-banner-button {
    display: flex;
    flex-direction: row;
    margin-top: 2 * variables.$spacing;
  }
}
