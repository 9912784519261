@use 'sass:math';
@use '../../variables';
@use '../../colors';

@mixin style {
  onep-address-field {
    onep-field {
      onep-address-field-input {
        display: flex;
        flex: 1;

        .onep-input {
          border-left: 0.5px solid colors.grey('light');
          border-radius: 0 math.div(variables.$spacing, 2)
            math.div(variables.$spacing, 2) 0;
        }
      }
    }
  }
}
