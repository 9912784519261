@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../mixins';
@use '../../text';

@mixin icon-button {
  padding: 0;
  height: variables.$spacing * 4;
  width: variables.$spacing * 4;
  background-color: transparent;
  border-radius: math.div(variables.$spacing, 2);
  color: colors.grey('dark');
  svg {
    font-size: 24px;
  }
}

@mixin icon-table-button {
  background-color: colors.white();
}

@mixin button-mini {
  @include text.button-mini;
  padding: variables.$spacing * 0.75 variables.$spacing * 2;
}

@mixin button-small {
  @include text.button-small;
  padding: variables.$spacing * 1.5 variables.$spacing * 3;
  @include mixins.onep-small-and-down {
    @include text.button-small;
    @include button;
  }
}

@mixin button {
  @include text.button;
  padding: variables.$spacing * 2 variables.$spacing * 3;
  @include mixins.onep-small-and-down {
    @include text.button-small;
  }
}

@mixin button-loading {
  background-color: colors.red('light') !important;
  .onep-button-background-loading {
    display: inline-block;
    background-color: colors.red('classic') !important;
    left: 0;
    top: 0;
    border-radius: 4px;
    position: absolute;
    height: 100%;
    width: 0;
  }
}

@mixin primary {
  @include button;
  background-color: colors.red('classic');
  border-radius: 4px;
  .onep-button-label {
    position: relative;
    color: colors.white();
    @if colors.contrast() {
      color: colors.grey('light-black');
    }
  }

  .onep-button-background-loading {
    display: none;
  }

  &.onep-button-loading {
    @include button-loading;
  }
}

@mixin secondary {
  @include button;
  @include text.button;
  background-color: colors.white();
  border: solid 1px colors.red('classic');
  border-radius: 4px;
  .onep-button-label {
    position: relative;
    color: colors.grey('light-black');
  }

  &.onep-button-loading {
    @include button-loading;

    .onep-button-label {
      color: colors.white();
      @if colors.contrast() {
        color: colors.grey('light-black');
      }
    }
  }
}

@mixin tertiary {
  @include button;
  padding-left: 8px !important;
  padding-right: 8px !important;
  color: colors.grey('light-black');
  background-color: transparent;
}

@mixin gradient {
  @include primary;
  background: linear-gradient(96.54deg, #ec3a62 9.52%, #ef685d 97.29%);
}

@mixin gradient-hover {
  .onep-button-gradient {
    position: relative;
    z-index: 1;
  }

  .onep-button-gradient::before {
    border-radius: 4px;
    position: absolute;
    transition: opacity ease-in-out 300ms;
    background: #ce1c44;
    background: linear-gradient(96.54deg, #ce1c44 9.52%, #e55e53 97.29%);
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  .onep-button-gradient:hover::before {
    opacity: 1;
  }
}

@mixin style {
  .onep-button-icon.hide-icon {
    display: none;
  }
  .roboto-condensed {
    font-family: 'Roboto condensed', var(--description-font), sans-serif !important;
  }

  button.onep-button {
    border: 0;
    outline: none;
    box-sizing: border-box;
    transition: all ease-in-out 300ms;

    cursor: pointer;
    position: relative;

    &.onep-button-primary {
      @include primary;

      &.onep-button-small {
        @include button-small;
      }

      &.onep-button-mini {
        @include button-mini;
      }
    }

    &.onep-button-secondary {
      @include secondary;

      &.onep-button-small {
        @include button-small;
      }

      &.onep-button-mini {
        @include button-mini;
      }
    }

    &.onep-button-tertiary {
      @include tertiary;

      &.onep-button-small {
        @include button-small;
      }

      &.onep-button-mini {
        @include button-mini;
        line-height: variables.$spacing * 2;
        color: colors.grey('dark');
      }
    }

    &.onep-button-gradient {
      @include gradient;
      &.onep-button-small {
        @include button-small;
      }

      &.onep-button-mini {
        @include button-mini;
        line-height: variables.$spacing * 2;
        color: colors.grey('dark');
      }
    }

    &.onep-button-icon {
      @include icon-button;
    }

    &.onep-button-icon-table {
      @include icon-table-button;
    }
  }
  button:focus:not(button:disabled),
  button:hover:not(button:disabled) {
    &.onep-button-primary {
      background-color: colors.red('dark');
      transition: box-shadow ease-in-out 300ms;
    }

    &.onep-button-secondary {
      background-color: colors.red('dark');
      transition: all ease-in-out 300ms;

      .onep-button-label {
        color: colors.white();
      }
    }

    &.onep-button-tertiary {
      text-decoration: underline;
      transition: all ease-in-out 300ms;

      &.roboto-condensed {
        &.onep-button-mini {
          color: colors.grey('light-black');
          text-decoration-color: colors.grey('light-black');
        }
      }
    }

    &.onep-button-icon {
      color: colors.grey('light-black');
    }

    &.onep-button-icon-table {
      background-color: colors.grey('light');
    }
  }

  button:active {
    &.onep-button-primary {
      color: colors.white();
      background-color: colors.red('dark');
      transition: background-color ease-in 300ms;
    }

    &.onep-button-secondary {
      color: colors.white();
      background-color: colors.red('dark');
      transition: background-color ease-in 300ms;
    }

    &.onep-button-tertiary {
      text-decoration: underline;
      transition: all ease-in-out 300ms;
    }

    &.onep-button-gradient {
      color: colors.white();
      background: linear-gradient(96.54deg, #ce1c44 9.52%, #e55e53 97.29%);
      transition: background ease-in 300ms;
    }
  }

  button:disabled {
    cursor: default;
    pointer-events: none;

    &.onep-button-primary {
      box-shadow: none;
      background-color: colors.grey('soft');
    }

    &.onep-button-secondary {
      background-color: colors.white();
      color: colors.grey('light');
      border: solid 1px colors.grey('light');
      border-radius: 4px;

      .onep-button-label {
        color: colors.grey('light');
      }
    }

    &.onep-button-tertiary {
      .onep-button-label {
        color: colors.grey('light');
      }
    }

    &.onep-button-gradient {
      box-shadow: none;
      background: colors.grey('soft');
    }

    &.onep-button-icon {
      color: colors.grey('light');
    }
  }
  @include gradient-hover;
}
