@use 'sass:map';
@use '../../variables';
@use '../../colors';
@use '../../text';
@use './field-variables';

@mixin style {
  onep-tag-field {
    onep-field-prefix {
      font-size: field-variables.$input-font-size + 8px;
      color: colors.grey('medium');
      z-index: 4;
      position: absolute;
      left: 15px;
      top: 12px;
    }
    input {
      padding: 16px 16px 16px 48px !important;
      &.overlay-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    input::placeholder {
      @include text.body-1;
      color: colors.grey('medium') !important;
    }
  }
  .onep-tag-field-create-option {
    font-weight: 700 !important;
  }
}
