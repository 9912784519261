@use 'sass:map';
@use '../../variables';
@use 'field-variables';
@use '../../colors';
@use '../../grid';

@mixin style {
  onep-input-inline {
    display: flex;
    margin-bottom: variables.$spacing;
    @include grid.onep-horizontal-tablet-and-down {
      margin-bottom: variables.$spacing * 2;
    }
    color: colors.grey('medium');
    font-size: variables.$small-font-size;
    font-weight: 400;
    line-height: 14px;
    font-family: var(--description-font), sans-serif;
    height: 0;
    max-height: 0;
    transition: all ease-in-out 400ms;
    opacity: 0;

    &.onep-input-inline-error {
      color: colors.orange('error');
      block-size: fit-content;
      max-height: 25px;
      opacity: 1;
      transition: all ease-in-out 400ms;
      margin-bottom: variables.$spacing * 2;
    }

    &.onep-input-inline-helper {
      max-height: 500px;
      height: auto;
      opacity: 1;
      transition: all ease-in-out 400ms;
      margin-bottom: variables.$spacing * 2;
    }
  }
}
