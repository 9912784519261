@use '../../variables';
@use '../../colors';
@use '../../mixins';
@use '../../text';

@mixin badge($state) {
  width: variables.$spacing;
  height: variables.$spacing;
  border-radius: 50px;
  margin-right: variables.$spacing;
  @include mixins.onep-small-and-down {
    display: none;
  }
  @if $state == new {
    background-color: colors.blue('brand');
  } @else if $state == accepted {
    background-color: colors.green('dark');
  } @else if $state == denied {
    background-color: colors.orange('error');
  } @else {
    background-color: colors.grey('medium');
  }
}

@mixin state($state) {
  @include mixins.onep-small-and-down {
    @include text.body-2;
    @if $state == new {
      color: colors.blue('brand');
    } @else if $state == accepted {
      color: colors.green('dark');
    } @else if $state == denied {
      color: colors.orange('error');
    } @else {
      color: colors.grey('medium');
    }
  }
}

@mixin style {
  onep-state-label {
    display: flex;
    align-items: center;
    @include text.body-3;
    .onep-state-label-new {
      @include state(new);
    }
    .onep-state-label-accepted {
      @include state(accepted);
    }
    .onep-state-label-denied {
      @include state(denied);
    }
    .onep-state-label-completed {
      @include state(completed);
    }
    .onep-state-label-new-badge {
      @include badge(new);
    }
    .onep-state-label-accepted-badge {
      @include badge(accepted);
    }
    .onep-state-label-denied-badge {
      @include badge(denied);
    }
    .onep-state-label-completed-badge {
      @include badge(completed);
    }
  }
}
