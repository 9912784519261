@use '../../variables';
@use '../../text';
@use '../../colors';
@use '../../mixins';

$spacing: variables.$spacing;

@mixin style {
  onep-order-summary {
    display: flex;
    flex-direction: column;
    padding: $spacing $spacing * 2 $spacing * 2 $spacing * 2;
    border-radius: 4px;
    background-color: colors.grey('extra-light');
    border: 1px solid colors.grey('light');

    onep-order-summary-section {
      border-bottom: 1px solid colors.grey('light');
      .onep-order-summary-see-more {
        @include text.body-3;
        @include text.medium;
        cursor: pointer;
        border-top: 1px solid colors.grey('light');
        justify-content: center;
        text-align: center;
        margin-left: -16px;
        width: calc(100% + 32px);
        padding: 10px 0;
        svg {
          color: colors.grey('medium');
          font-size: 22px;
          transition: all ease-in-out 300ms;
        }
        &:hover {
          svg {
            color: colors.grey('light-black');
          }
        }
      }

      .onep-order-summary-section-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: variables.$spacing * 2;

        onep-order-summary-tax-item {
          .onep-summary-item-title {
            @include text.body-3;
            line-height: 0 !important;
          }

          .onep-summary-info-section {
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
              color: colors.grey('dark');
              font-size: 24px;
              margin: -2px 0 0 2px;
            }
          }
        }

        onep-order-summary-item,
        onep-order-summary-tax-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: variables.$spacing * 3;

          .onep-text-subheading-1 {
            font-size: 18px;
            line-height: 20px;
            @include mixins.onep-small-and-down {
              font-size: 16px;
            }
          }

          .onep-summary-amount-section {
            display: flex;
            flex: 0 0 90px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0;
            margin-left: 24px;

            .onep-order-sumarry-erase-button {
              padding-right: 0 !important;
              text-underline-color: colors.grey('dark');

              .onep-button-label {
                @include text.link-3;
                color: colors.grey('dark');
                text-decoration: underline;
                margin: 0;
              }

              :hover {
                text-decoration-color: colors.grey('light-black');
                color: colors.grey('light-black');
              }

              margin: 0;
            }
          }

          p {
            margin: 0;
            line-height: 24px;
          }

          .onep-summary-item-description {
            overflow: hidden;
          }

          .onep-summary-item-description,
          .onep-summary-item-sub-description {
            color: colors.grey('dark');
            margin: 0;
            max-width: 180px;

            span {
              display: block !important;
            }
          }

          .onep-button-tertiary {
            padding: 0 !important;
          }
        }
      }

      &.onep-order-summary-expand {
        position: relative;
        .onep-order-summary-see-more {
          display: flex;
          align-items: center;
          box-shadow: 0px -24px 50px rgba(0, 0, 0, 0.1);
        }
        .onep-order-summary-section-container {
          transition: all ease-in-out 500ms;
          max-height: 280px;
          height: 280px;
          overflow: hidden;
        }
        &.onep-order-summary-expanded {
          .onep-order-summary-see-more {
            svg {
              transition: all ease-in-out 300ms;
              transform: rotate(180deg);
            }
            box-shadow: inherit;
          }
          .onep-order-summary-section-container {
            height: 100%;
            max-height: 10000px;
            overflow: hidden;
            transition: all ease-in-out 1000ms;
          }
        }
      }
    }

    onep-order-summary-total {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: variables.$spacing * 2;
      margin-bottom: variables.$spacing * 3;
      .onep-order-summary-total-sublabel {
        color: colors.grey('dark');
      }
      p {
        margin: 0;
      }
    }

    onep-order-summary-cgu {
      @include text.caption-1;
      color: colors.grey('dark');
      text-align: justify;
      p {
        margin-bottom: variables.$spacing;
      }
    }
  }
}
