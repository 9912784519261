@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';
@use 'field-variables';

$-select-menu-height: 246px;

@mixin select-base {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex: 1;
  display: inline-flex;
  color: colors.grey('light-black');
  border-radius: math.div(variables.$spacing, 2);
  @include text.body-1;
  line-height: inherit;
  &:focus {
    outline: none;
  }
  &.overlay-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .select-suffix {
      transform: rotate(180deg);
    }
  }
  &.onep-select-disabled {
    cursor: default;
    pointer-events: none;
    color: colors.grey('medium');
    border-color: colors.grey('light');
    background-color: colors.grey('extra-light');
    .select-suffix {
      color: colors.grey('medium');
    }
  }
  &.onep-input-error {
    border: 1px solid colors.orange('error');
    box-shadow: 0 0 0 1px colors.orange('error');
    outline: none;
  }

  .select-suffix {
    transform: rotate(0deg);
    transition: transform 300ms;
    font-size: 24px;
  }

  .onep-select-suffix {
    position: absolute;
  }
}

@mixin select-border {
  border: 1px solid colors.grey('light');
  &:focus {
    border: 1px solid colors.grey('light-black');
    box-shadow: 0 0 0 1px colors.grey('light-black');
  }
}

@mixin select-no-border {
  justify-content: flex-end;
  .selected-display {
    padding: variables.$spacing * 1.5 + 1px variables.$spacing * 4 - 4px
      variables.$spacing * 1.5 + 1px variables.$spacing * 2;
    height: variables.$font-size + 2px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    @include text.medium();

    .selected-display-image {
      @include select-image-reverse;
    }
  }

  .select-suffix {
    color: colors.grey('medium');
  }

  .onep-select-suffix {
    right: 0;
    top: variables.$spacing;
  }
}

@mixin select-menu-base {
  .select-icon {
    font-size: 22px;
  }
  &.onep-select-overlay {
    cursor: pointer;
    display: flex;
    align-items: stretch;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
}

@mixin select-image {
  width: variables.$select-img-width;
  margin-right: variables.$spacing;
}
@mixin select-image-reverse {
  width: variables.$select-img-width;
  margin-left: variables.$spacing;
}

@mixin style {
  onep-select {
    @include select-base;
  }
  .onep-select {
    @include select-border;

    .selected-display {
      padding: variables.$spacing * 3 + 1 variables.$spacing * 2
        variables.$spacing + 1 variables.$spacing * 2;
      height: variables.$font-size + 2px;
      width: 100%;
      box-sizing: initial;

      .selected-display-image {
        @include select-image;
      }
    }

    .onep-select-suffix {
      color: colors.grey('medium');
      right: variables.$spacing * 2 - 1px;
      top: variables.$spacing * 2 - 1px;
      @include mixins.onep-small-and-down {
        top: variables.$spacing * 2 - 2;
      }
    }
  }

  .onep-select-no-border {
    @include select-no-border;
  }

  .onep-select-no-container {
    @include select-no-border;
    &:hover {
      .onep-select-container-label {
        color: colors.grey('dark') !important;
      }
    }
    .selected-display {
      padding: 0 variables.$spacing * 3 - 2px 0 0;
    }
    .onep-select-container-label {
      @include text.body-3;
      @include text.bold;
    }
    .onep-select-suffix {
      top: inherit;
      .select-suffix {
        font-size: variables.$spacing * 2.5;
        margin-left: math.div(variables.$spacing, 2);
      }
    }
  }

  .onep-select-quantity {
    @include select-border;
    border: 1px solid colors.grey('light');
    justify-content: center;
    flex: 0;
    flex-basis: 64px;

    .selected-display {
      padding: variables.$spacing * 1.5 variables.$spacing * 4
        variables.$spacing * 1.5 variables.$spacing * 2;
      height: variables.$spacing * 2;
      width: 100%;
      text-align: center;
    }

    .onep-select-suffix {
      color: colors.grey('medium');
      right: math.div(variables.$spacing, 2);
      top: variables.$spacing + 1;
    }
  }

  .onep-select-country {
    @include select-border;
    border-right: 0.5px solid colors.grey('light');
    border-radius: math.div(variables.$spacing, 2) 0 0
      math.div(variables.$spacing, 2);
    justify-content: center;
    flex: 0;
    flex-basis: 64px;
    height: fit-content;

    .selected-display {
      padding: variables.$spacing * 2 - 3 variables.$spacing * 2 - 2
        variables.$spacing * 2 - 3 variables.$spacing * 2 - 2;
      display: flex;
      align-items: center;
      width: max-content;
    }

    .onep-select-suffix {
      color: colors.grey('medium');
      position: relative;
      display: inline-block;
    }

    .onep-select-value {
      display: inline-block;
      margin-right: variables.$spacing * 1.5;
      width: variables.$spacing * 3 + 2;
      height: variables.$spacing * 2.5;
    }
  }

  .onep-select-menu-mobile-header {
    background-color: colors.white();
    border-bottom: 1px solid colors.grey('soft');
    display: flex;
    flex: 1;
    height: variables.$spacing * 7;
    justify-content: flex-end;
    align-items: center;
    .onep-select-menu-mobile-close {
      color: colors.grey('medium');
      padding-right: 12px;
      font-size: 24px;
    }
  }
}

@mixin overlays-classes-style($overlayContainerClass: 'div') {
  // The class is applied on the same div as the overlay container class
  #{$overlayContainerClass} {
    @include select-menu-base;
    &.onep-select-menu {
      border-bottom: 1px solid colors.grey('light');
      border-left: 1px solid colors.grey('light');
      border-right: 1px solid colors.grey('light');
      border-radius: 0 0 math.div(variables.$spacing, 2)
        math.div(variables.$spacing, 2);
      display: flex;
      background-color: variables.$white;
      flex-direction: column;
      margin: 0 1px 0 1px;
      overflow: auto;

      .onep-select-menu-mobile-content {
        width: 100vw;
        overflow-y: auto;
        position: relative;
        height: 100vh;
        .select-option-icon {
          font-size: 24px;
        }
      }

      .onep-select-menu-content {
        overflow-y: auto;
        position: relative;
        max-height: $-select-menu-height;
        &:last-child {
          padding-bottom: 8px;
        }
      }

      .select-option {
        flex-direction: column;

        .select-option-image {
          @include select-image;
        }

        .select-option-content {
          @include text.body-2;
          height: variables.$spacing * 5 + math.div(variables.$spacing, 2);

          @include mixins.onep-small-and-down {
            height: variables.$spacing * 6;
          }

          &.select-option-selected {
            font-weight: bold;
          }

          display: flex;
          align-items: center;
          padding-left: variables.$spacing * 2;

          .select-option-icon {
            margin-left: auto;
          }
        }

        &:hover {
          background-color: colors.grey('soft');
        }
      }

      .select-option-label {
        width: 90%;
      }

      .select-option-icon {
        color: colors.green('success');
        margin-right: variables.$spacing * 2;
        font-size: 24px;
      }
    }

    &.onep-select-menu-no-border,
    &.onep-select-menu-row-control {
      border: 1px solid colors.grey('light');
      border-radius: 4px;
    }

    &.onep-select-menu-row-control {
      display: flex;
      background-color: variables.$white;
      flex-direction: column;

      .select-option {
        margin-top: 2px;
        margin-bottom: 2px;
        text-align: center;
        justify-content: center;
        height: 32px;
        display: flex;
        align-items: center;

        .select-option-content {
          @include text.body-1;
        }

        &:hover {
          background-color: colors.grey('soft');
        }
      }

      .select-option-label {
        width: 100%;
      }

      .selected {
        font-weight: bold;
      }
    }

    &.onep-select-menu-no-container {
      .select-option {
        .select-option-content {
          @include text.body-3;
        }
      }
    }

    &.onep-select-menu-quantity {
      .select-option {
        .select-option-content {
          padding: 0 math.div(variables.$spacing, 2) 0 0;
          span {
            text-align: center;
          }
          .select-option-icon {
            display: none;
          }
          .select-option-label {
            width: 100%;
          }
        }
        .select-option-selected {
          background-color: colors.grey('extra-light');
        }
      }
    }

    &.onep-select-menu-country {
      .select-option {
        .select-option-content {
          span {
            width: variables.$spacing * 3 + 2;
            height: variables.$spacing * 2.5;
          }
        }
      }
      .onep-select-menu-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &.onep-select-menu-mobile {
      border-left: 1px solid colors.grey('soft');
      border-right: 1px solid colors.grey('soft');
      border-bottom: 1px solid colors.grey('soft');
      height: 100vh;
      width: 100vw;
      margin: 0 !important;
      &.onep-select-menu-row-control {
        .select-option {
          all: inherit;
          flex-direction: column;

          .select-option-content {
            @include text.body-1;
            text-align: center;
            height: variables.$spacing * 5 + math.div(variables.$spacing, 2);
            display: flex;
            align-items: center;
            padding-left: variables.$spacing * 2;

            .select-option-icon {
              margin-left: auto;
            }
          }
          &:hover {
            background-color: colors.grey('soft');
          }
        }
      }
      .select-option {
        .select-option-divider {
          margin-left: 16px;
          margin-right: 16px;
          border-bottom: 1px solid colors.grey('soft');
        }
      }
    }
  }
}
