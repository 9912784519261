@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-setting-card:hover {
    border: 1px solid colors.grey('light-black');

    .onep-setting-card-action-container {
      .erase-button {
        display: flex;
        position: absolute;
        right: variables.$spacing * 2;
        cursor: pointer;
        @include text.link-2;
        color: colors.grey('dark');
        text-decoration: underline;
      }
    }
  }

  onep-setting-card {
    transition: all ease-in-out 250ms;
    display: flex;
    flex-direction: row;
    padding: variables.$spacing * 2;
    border-radius: math.div(variables.$spacing, 2);
    border: 1px solid colors.grey('light');
    position: relative;
    background-color: colors.white();

    &.onep-setting-card-wide {
      &:hover {
        background-color: colors.grey('extra-light');
      }
    }
    .onep-setting-card-action-container {
      display: flex;
      margin-left: variables.$spacing * 2;
      min-width: variables.$spacing * 7;
      .erase-button {
        display: none;
      }
    }

    .onep-setting-card-container {
      display: flex;
      flex-direction: column;
      @include text.body-3;
      color: colors.grey('dark');
    }

    &:hover {
      border: 1px solid colors.grey('light-black');
    }
  }

  onep-setting-card-title {
    margin-bottom: math.div(variables.$spacing, 2);
    color: colors.grey('light-black');
  }
}
