@forward 'private/column';

@use 'private/datatable';
@use 'private/form';
@use 'private/fields';
@use 'private/icon';
@use 'private/text';
@use 'private/ui';
@use 'private/layout';

@use 'colors';

@import '@angular/cdk/overlay-prebuilt';
@import './font-inter.scss';
@import 'https://prod-wedoostore.s3.eu-central-1.amazonaws.com/final/ds1/ds1_tokens.css';

@mixin style {
  @include -all-style();
  @include -all-overlays-classes-style();
}

@mixin micro-front($rootElement, $overlayContainerClass) {
  #{$rootElement},
  #{$overlayContainerClass} {
    @include -all-style();
  }

  @include -all-overlays-classes-style($overlayContainerClass);
}

@mixin -all-style() {
  @include datatable.style;
  @include form.style;
  @include fields.style;
  @include icon.style;
  @include text.style;
  @include ui.style;
  @include layout.style;

  body {
    color: colors.grey('light-black');
    font-family: var(--description-font), sans-serif;
  }
}

@mixin -all-overlays-classes-style($overlayContainerClass: 'div') {
  // Contains all the styles of overlays as
  // they need to be applied differently if used in a microfront.
  @include fields.overlays-classes-style($overlayContainerClass);
}
