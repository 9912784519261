@use 'sass:map';
@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  .onep-date-picker {
    width: 284px !important;
    border: 1px solid colors.grey('light');
    border-radius: 4px;
    padding: 16px;
    min-height: 292px;
    .datepicker__wrapper {
      height: 100% !important;
      aa-navigation {
        padding: 0;
        padding-bottom: 16px;
        @include text.body-1;
        .navigation__button {
          border: none;
        }
      }
      .datepicker__weekdays-wrapper {
        .datepicker__weekdays-container {
          padding: 0;
          .datepicker__weekdays {
            .datepicker__weekday {
              @include text.body-3;
              @include text.medium;
              color: colors.grey('medium');
            }
          }
        }
      }
      .datepicker__main {
        height: 100% !important;
        .datepicker__calendar-wrapper {
          height: 100% !important;
          .datepicker__calendar-container {
            padding: 8px 16px 8px 16px;
            .datepicker__week {
              .datepicker__day {
                height: 32px !important;
                .datepicker__button {
                  border-radius: 4px;
                  @include text.body-3;
                  @include text.medium;
                  color: colors.grey('light-black');
                  &:hover {
                    background-color: colors.grey('extra-light') !important;
                  }
                }
                &.is-selected {
                  .datepicker__button {
                    background-color: colors.grey('soft');
                  }
                }
                &.is-today {
                  .datepicker__button {
                    background-color: colors.white();
                  }
                }
                border: none;
              }
            }
          }
        }
      }
      .onep-date-picker-month-row {
        align-items: center;

        .onep-date-picker-month-label {
          line-height: 24px;
          margin: 0;
        }

        svg {
          cursor: pointer;
          font-size: 28px;
        }

        .onep-date-picker-icon-left {
          transform: rotate(90deg);
        }

        .onep-date-picker-icon-right {
          transform: rotate(270deg);
        }

        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;
      }

      .onep-date-picker-week-row {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        color: colors.grey('medium');
        margin-bottom: 4px;

        .onep-date-picker-week-day {
          display: flex;
          height: 32px;
          width: 32px;
          justify-content: center;
          align-items: center;
          margin-right: 4px;
        }

        .onep-date-picker-week-day:last-child {
          margin: 0;
        }
      }

      .onep-date-picker-days {
        display: flex;
        flex-direction: row;
        padding-bottom: 4px;
        flex-wrap: wrap;

        .onep-date-picker-days-day {
          cursor: pointer;
          display: flex;
          height: 32px;
          width: 32px;
          margin-right: 4px;
          margin-bottom: 4px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;

          &:hover:not(.out-of-month-day) {
            background-color: colors.grey('extra-light');
          }

          &.last-day {
            margin-right: 0;
          }

          &.selected-day {
            background-color: colors.grey('soft');
            font-weight: bold;
          }

          &.out-of-month-day {
            color: colors.grey('medium');
          }
        }

        .onep-date-picker-days-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-bottom: 16px;
        }
      }
    }
  }
}
