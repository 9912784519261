@use 'sass:map';
@use 'sass:math';
@use '../../variables';
@use '../../mixins';
@use '../../colors';
@use '../../text';

@mixin style {
  .onep-drag-and-drop-container {
    display: flex;
    background-color: colors.grey('extra-light');
    height: 168px;
    width: 100%;
    border: 1px dashed colors.grey('light');
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include text.body-3;
    &.onep-drag-and-drop-active {
      border-color: colors.grey('medium');
    }
  }

  .onep-drag-and-drop-content {
    padding: 0px 56px;
    .onep-drag-and-drop-loading-container {
      text-align: center;
      .onep-spinner {
        margin: auto;
        position: unset;
        mix-blend-mode: unset;
      }
      .onep-drag-and-drop-loading-text {
        @include text.body-3;
        margin-top: 8px;
      }
    }
  }

  .onep-drag-and-drop-files {
    z-index: 1;
    font-size: variables.$font-size;
    text-align: center;
    div {
      margin-bottom: math.div(variables.$spacing, 2);
    }
    a[onepLink] {
      text-decoration: underline;
    }
  }

  .onep-drag-and-drop-inline {
    margin-top: 16px;
    @include text.body-3;
  }

  .onep-drag-and-drop-container:hover {
    cursor: pointer;
    border-color: colors.grey('medium');
  }
}
