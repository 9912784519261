@use 'sass:map';
@use 'sass:math';
@use '../../text';
@use '../../variables';
@use '../../colors';
@use '../../mixins';

@mixin style {
  a[onepLink] {
    outline: none;
    text-decoration: underline;
    transition: all ease-in-out 300ms;

    cursor: pointer;

    &.onep-link-default {
      @include text.link-1;

      &:hover {
        color: colors.grey('dark');
      }
    }

    &.onep-link-secondary {
      @include text.link-2;

      &:hover {
        color: colors.grey('dark');
      }
    }

    &.onep-link-tertiary {
      @include text.link-3;
      color: colors.grey('dark');

      &:hover {
        color: colors.grey('light-black');
      }
    }

    &.onep-link-navigation,
    &.onep-link-header {
      @include text.link-1;

      line-height: 24px;

      text-decoration: none;
      vertical-align: center;

      &.onep-link-icon-after {
        margin-right: -8px;

        svg {
          transform: rotate(-90deg);
        }
      }

      &.onep-link-icon-before {
        margin-left: -8px;
        svg {
          transform: rotate(90deg);
        }
      }

      .onep-link-icon {
        color: colors.red('classic');
        font-size: 24px;

        vertical-align: -7px;
      }

      .onep-link-text {
        vertical-align: baseline;
      }

      &:hover {
        color: colors.grey('dark');
      }
    }

    &.onep-link-header {
      font-weight: 500;
      .onep-link-icon {
        color: colors.grey('medium');
      }
    }

    &.onep-link-action {
      @include text.link-1;

      line-height: 24px;

      text-decoration: none;
      vertical-align: center;

      .onep-link-icon {
        color: colors.red('classic');
        vertical-align: -10px;
        @include mixins.onep-small-and-down {
          vertical-align: -9px;
          width: 28px !important;
          height: 28px !important;
          svg {
            font-size: 28px !important;
          }
          .onep-icon-circle {
            width: 22px !important;
            height: 22px !important;
            margin: 3px !important;
          }
        }
      }

      &.onep-link-icon-after {
        .onep-link-icon {
          margin-left: math.div(variables.$spacing, 2);
        }
      }

      &.onep-link-icon-before {
        .onep-link-icon {
          margin-right: math.div(variables.$spacing, 2);
        }
      }

      .onep-link-text {
        vertical-align: baseline;
      }

      &:hover {
        color: colors.grey('dark');
      }
    }

    &.onep-link-contact {
      @include text.link-1;
      color: colors.contact-blue();
    }

    &.onep-link-disabled {
      cursor: default;
      pointer-events: none;
      color: colors.grey('light');

      .onep-link-icon {
        color: colors.grey('light');
      }
    }
  }
}
