@use 'sass:map';
@use 'sass:math';
@use '../../variables';
@use '../../text';
@use '../../colors';
@use './field-variables';

@mixin style {
  onep-autocomplete {
    border-bottom: 1px solid colors.grey('light');
    border-left: 1px solid colors.grey('light');
    border-right: 1px solid colors.grey('light');
    border-radius: 0 0 math.div(variables.$spacing, 2)
      math.div(variables.$spacing, 2);
    display: flex;
    flex: 1;
    overflow: auto;
    background-color: variables.$white;
    flex-direction: column;
    &:last-child {
      padding-bottom: 8px;
    }
    onep-autocomplete-option {
      @include text.body-1;
      font-size: field-variables.$input-font-size;
      line-height: 100% !important;
      flex: 0 0 variables.$spacing * 5 + math.div(variables.$spacing, 2);
      flex-direction: column;
      display: flex;
      padding-left: variables.$spacing * 2;
      &:hover {
        background-color: colors.grey('soft');
      }
      .autocomplete-option-label {
        padding-top: variables.$spacing * 2 - 2;
      }
    }
  }
  onep-autocomplete-search {
    onep-autocomplete-search-section {
      display: flex;
      flex-direction: column;
      .onep-autocomplete-search-section-title {
        @include text.body-4;
        @include text.uppercase;
        @include text.bold;
        padding-left: variables.$spacing * 2;
        padding-top: variables.$spacing * 3;
        padding-bottom: variables.$spacing * 2;
      }
      onep-autocomplete-option {
        flex: 0 0 variables.$spacing * 5 + math.div(variables.$spacing, 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding-left: variables.$spacing * 2;
        &:hover {
          background-color: colors.grey('soft');
        }
        .autocomplete-option-label {
          padding-top: 0;
          @include text.body-3;
          @include text.capitalize;
        }
        .autocomplete-option-description {
          @include text.caption-1;
          color: colors.grey('medium');
        }
      }
    }
  }
}
