@use 'sass:map';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';
@use './input';

@mixin style {
  onep-label {
    &.onep-textarea-label {
      &.active {
        font-size: variables.$small-font-size;
        top: variables.$spacing * 2;
        /* -1px because border is 1px */
      }
    }
  }
  onep-textarea-header-component {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    background-color: colors.white();
    height: variables.$spacing * 4;
    left: 2px;
    top: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &.onep-textarea-disable {
      background-color: colors.grey('extra-light');
    }

    @include mixins.onep-small-and-down {
      height: variables.$spacing * 3;
    }
  }
  textarea[onepTextarea] {
    @include text.body-1();
    @include mixins.onep-small-and-down {
      @include text.body-2;
    }
    padding: variables.$spacing * 2;
    resize: none;
    width: 100%;
    border: 1px solid colors.grey('light');
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
  }
  textarea::placeholder {
    @include text.body-1;
    @include mixins.onep-small-and-down {
      @include text.body-2;
      color: colors.grey('medium');
    }
    color: colors.grey('medium');
  }
  textarea:focus {
    border: 1px solid colors.grey('light-black');
    box-shadow: inset 0 0 0 1px colors.grey('light-black');
    box-sizing: border-box;
  }
  textarea.onep-textarea-error {
    border: 1px solid colors.red('classic');
    box-shadow: inset 0 0 0 1px colors.red('classic');
    box-sizing: border-box;
  }
  textarea.onep-textarea-expandable {
    overflow: hidden;
    height: 56px;
    padding: 16px !important;
    @include mixins.onep-small-and-down {
      height: 48px;
    }
  }
  textarea:not(.onep-textarea-expandable) {
    height: 196px;
    padding: variables.$spacing * 4 variables.$spacing * 2.5 variables.$spacing *
      2 variables.$spacing * 2;
  }
  .onep-field-mobile {
    textarea:not(.onep-textarea-expandable) {
      height: 196px;
      padding: variables.$spacing * 4 variables.$spacing * 2.5
        variables.$spacing * 2 variables.$spacing * 2 !important;
    }
  }
  textarea:disabled {
    background-color: colors.grey('extra-light');
    border: 1px solid colors.grey('light');
  }
  .onep-textarea-error-label {
    @include text.caption-2();
    color: colors.orange('error');
    margin-top: variables.$spacing;
  }
}
