@use 'sass:map';
@use '../../variables';
@use '../../mixins';

@mixin style {
  onep-additional-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    onep-icon-circle-highlighted {
      cursor: pointer;
      position: absolute !important;
      left: -14px;
      font-size: 24px;
    }
    &.onep-additional-button-field {
      onep-icon-circle-highlighted {
        cursor: pointer;
        top: 12px;
        @media (max-width: 414px) {
          top: 10px;
        }
      }
    }
    &.onep-additional-button-right {
      onep-icon-circle-highlighted {
        cursor: pointer;
        right: -14px;
        left: inherit;
      }
    }
  }
}
