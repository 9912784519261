@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin icon {
  transition: all ease-in-out 250ms;
  font-size: variables.$spacing * 3;
  margin-left: variables.$spacing * 3;
}

@mixin style {
  onep-accordion {
    .onep-accordion-item-selected {
      background-color: colors.grey('extra-light');
    }
    onep-accordion-group {
      display: block;
      background-color: transparent;
      .onep-accordion-title {
        transition: all ease-in-out 250ms;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: variables.$spacing * 2;
        cursor: pointer;
        @include text.heading-5;
        @include text.bold;
        svg {
          @include icon;
          &.is-opened {
            transform: rotate(180deg);
          }
        }
      }
      .onep-accordion-body {
        transition: all ease-in-out 250ms;
        max-height: 100vh;
        transform: scaleY(1);
        transform-origin: top;
      }
      .onep-accordion-body.hidden {
        max-height: 0;
        transform: scaleY(0);
        overflow: hidden;
      }
      onep-accordion-item {
        position: relative;
        cursor: pointer;
        @include text.body-2;
        display: flex;
        max-height: variables.$spacing * 6;
        padding: variables.$spacing * 2 variables.$spacing * 6
          variables.$spacing * 2 variables.$spacing * 2;
        .onep-accordion-item-icon-selected {
          position: absolute;
          right: variables.$spacing * 2;
          top: variables.$spacing * 2 - 4;
          @include icon;
          color: colors.green('success');
        }
        onep-checkbox {
          display: inline-block;
          margin: -7px -4px;
          .onep-checkbox-visual-content {
            font-weight: normal;
          }
        }
      }
      onep-accordion-item:hover {
        background-color: colors.grey('extra-light');
      }
    }
  }
}
