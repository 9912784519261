@use 'sass:math';
@use '../../variables';
@use '../../colors';

@mixin style {
  .onep-timeline {
    background: none;
    font-size: 0.8em;
    margin: 0;

    .onep-timeline-separator {
      transform: rotate(-90deg);
      font-size: variables.$font-size + 6;
      color: colors.grey('medium');
    }

    span {
      color: colors.grey('medium');
      font-size: variables.$font-size - 2;
      font-weight: 400;
      line-height: variables.$spacing * 2 - 2;

      &.dot {
        height: variables.$spacing;
        width: variables.$spacing;
        margin: math.div(variables.$spacing, 2);
        border-radius: 50%;
        border: solid 1px colors.grey('medium');
        display: inline-block;
      }

      &.active-dot {
        font-weight: 700;
        border-color: colors.red('classic');
        background-color: colors.red('classic');
      }
    }

    .active {
      .onep-timeline-separator {
        color: colors.grey('light-black');
      }

      span {
        color: colors.grey('light-black');
        font-weight: 700;
      }
    }

    li {
      list-style: none;
      float: left;

      div {
        display: flex;
        height: 20px;
        align-items: center;
        float: left;
      }
    }
  }
}
