@use '../../variables';
@use '../../colors';
@use 'field-variables';
@use '../../text';
@use '../../mixins';

@mixin inherit-button-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin style {
  onep-radio-button {
    &.onep-input-error {
      button {
        &.onep-checked::after {
          background-color: colors.orange('error');
        }

        &.onep-checked::before {
          border: 1px solid colors.orange('error');
          background-color: colors.white();
        }

        &.onep-not-checked::before {
          border: 1px solid colors.orange('error');
          background-color: colors.orange('light');
        }
      }
    }
    &.onep-radio-button-disable {
      cursor: default;
      pointer-events: none;
      button {
        color: colors.grey('light');
        &.onep-checked::after {
          background-color: colors.grey('light');
        }

        &.onep-checked::before {
          border: 1px solid colors.grey('light');
          background-color: colors.white();
        }

        &.onep-not-checked::before {
          border: 1px solid colors.grey('light');
          background-color: colors.grey('extra-light');
        }
      }
      .radio-button-title {
        color: colors.grey('light') !important;
      }
      .radio-button-subtitle {
        color: colors.grey('light') !important;
      }
      .radio-button-description {
        color: colors.grey('light') !important;
      }
    }
    .radio-button-row {
      display: flex;
      flex-direction: row;
    }

    .radio-button-col {
      display: flex;
      flex-direction: column;

      .radio-button-content {
        display: flex;
        flex-direction: row;
        gap: 4px;

        .radio-button-title-description {
          @include text.body-2();
          font-weight: 500;
        }
        .radio-button-title-only {
          @include text.body-2();
        }
        .radio-button-subtitle {
          @include text.body-3();
        }
        .radio-button-description {
          @include text.body-3();
          color: colors.grey('medium');
        }
      }
    }

    button {
      position: relative;
      @include inherit-button-style;
      &.onep-checked::before,
      &.onep-checked::after,
      &.onep-not-checked::before {
        transition: all ease-in-out 300ms;
        content: '';
        vertical-align: -16%;
        display: inline-block;
        margin-right: 12px;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 50px;
        background-color: colors.red('classic');
      }
      &.onep-checked::before {
        border: 1px solid colors.red('classic');
        width: variables.$spacing * 2;
        height: variables.$spacing * 2;
        left: variables.$spacing * 2;
        background-color: colors.white();
      }
      &.onep-checked::after {
        position: absolute;
        width: variables.$spacing;
        height: variables.$spacing;
        left: 5px;
        top: 5px;
        background-color: colors.red('classic');
      }
      &.onep-not-checked::before {
        border: 1px solid colors.grey('light');
        width: variables.$spacing * 2;
        height: variables.$spacing * 2;
        left: variables.$spacing * 2;
        background-color: colors.white();
      }
    }
  }
}
