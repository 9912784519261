@use '../../variables';
@use '../../colors';

@mixin style {
  .onep-spinner {
    border-radius: 50%;
    cursor: progress;
    display: inline-block;
    overflow: hidden;
    position: absolute;
    transition: all 200ms ease-out;
    vertical-align: top;
    height: 32px;
    width: 32px;
    z-index: 4;
    border-width: 2px;
    border-style: solid;
    border-color: colors.grey('soft');
    border-top-color: colors.red('classic');
    animation: animation 0.7s linear infinite;
    mix-blend-mode: unset;
  }

  @keyframes animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
