@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-article {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    .onep-article-image {
      height: 150px;
      object-fit: cover;
      margin-bottom: 24px;
      border-radius: 4px 4px 0 0;
      border: solid 1px colors.grey('soft');
    }
    .onep-article-container {
      position: relative;
      padding-right: 16px;
    }
    .onep-article-title {
      @include text.heading-5;
      @include text.bold;
      margin-bottom: 8px;
      max-height: 72px;
      overflow: hidden;
    }
    p {
      @include text.body-3;
      color: colors.grey('medium');
      margin: 0;
      margin-bottom: 24px;
      max-height: 80px;
      overflow: hidden;
    }
    onep-article-link {
      @include text.link-2();
      display: flex;
      align-self: flex-end;
      justify-content: center;
      align-items: center;
      text-align: right;
      &:hover {
        color: colors.grey('dark');
      }
      .onep-article-link-caret {
        transform: rotate(270deg);
        color: colors.red('classic');
        font-size: 20px;
      }
    }
  }

  onep-article:hover {
    cursor: pointer;
    .onep-article-title {
      * {
        text-decoration: underline;
      }
    }
  }
}
