@use 'sass:map';
@use '../../variables';
@use '../../mixins';
@use '../../colors';
@use '../../text';

@mixin style {
  .onep-file-uploader-multiple {
    .onep-file-uploader-container {
      height: 100%;
      min-height: 92px;
      @include mixins.onep-small-and-down {
        min-height: 72px;
      }
      .onep-file-uploader-content {
        padding: 24px 0;
        @include mixins.onep-small-and-down {
          padding: 16px 0px;
        }
      }
      .onep-file-uploader-queue {
        cursor: default;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 0 16px 16px;
        height: 100%;
        max-height: 800px;
        overflow: hidden;
        transition: all ease-in-out 300ms;
        onep-file-uploader-item {
          justify-content: flex-start;
        }
        onep-file-uploader-item:not(:last-child) {
          transition: all ease-in-out 300ms;
          margin-bottom: variables.$spacing;
        }
        &.onep-file-uploader-queue-hide {
          height: 0;
          max-height: 0;
          padding: 0;
          transition: all ease-in-out 300ms;
          &.onep-file-uploader-queue:before {
            display: none;
          }
        }
      }
      .onep-file-uploader-queue:before {
        content: ''; /* This is necessary for the pseudo element to work. */
        display: block; /* This will put the pseudo element on its own line. */
        margin: 0 auto; /* This will center the border. */
        width: 100%; /* Change this to whatever width you want. */
        padding-top: 20px; /* This creates some space between the element and the border. */
        border-top: 1px solid colors.grey('light');
        @include mixins.onep-small-and-down {
          padding-top: 16px;
        }
      }
      &.onep-file-uploader-active {
        .onep-file-uploader-content {
          padding: 24px 0 20px;
          @include mixins.onep-small-and-down {
            padding: 16px 0px;
          }
        }
      }
    }
  }
  .onep-file-uploader-container {
    position: relative;
    display: flex;
    background-color: colors.grey('extra-light');
    height: 92px;
    border: 1px dashed colors.grey('light');
    border-radius: 4px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 24px;
    @include text.body-3;
    @include mixins.onep-small-and-down {
      height: 72px;
      padding: 0 16px;
    }

    .onep-file-uploader-content {
      width: 100%;
      .onep-spinner {
        margin: auto;
        position: unset;
        mix-blend-mode: unset;
        height: 20px;
        width: 20px;
        margin-right: variables.$spacing;
      }
    }
  }

  onep-file-uploader-item {
    cursor: pointer;
    z-index: 1;
    font-size: variables.$font-size;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    @include text.body-3;
    @include mixins.onep-small-and-down {
      @include text.body-1;
    }
    onep-icon-circle-highlighted {
      &.onep-file-uploader-clear-icon {
        margin-left: variables.$spacing;
        color: colors.grey('medium');
        opacity: 1;
        transition: all ease-in-out 150ms;
        &.onep-file-uploader-clear-icon-hide {
          opacity: 0;
          transition: all ease-in-out 150ms;
        }

        .onep-icon-circle {
          box-shadow: inherit;
          border: none;
          background-color: colors.grey('soft');
          width: 14px;
          height: 14px;
          margin: 3px;
        }
      }
    }
    svg {
      font-size: 20px;
      &.onep-file-uploader-checked-icon {
        margin-right: variables.$spacing;
        color: colors.green('success');
      }
    }
  }

  .onep-file-uploader-inline {
    margin-top: 8px;
    @include text.caption-1();
    color: colors.orange('error');
  }

  .onep-file-uploader-container:hover {
    cursor: pointer;
    border-color: colors.grey('medium');
  }
}
