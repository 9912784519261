@use 'sass:map';
@use 'sass:math';
@use '../variables';
@use 'ui/additional-button';
@use 'ui/link';
@use 'ui/tooltip';
@use 'ui/header';
@use 'ui/spinner';
@use 'ui/button';
@use 'ui/table';
@use '../colors';
@use '../text';
@use 'ui/field-display';
@use 'ui/section';
@use 'ui/info-card';
@use 'ui/validation-card';
@use 'ui/error-banner';
@use 'ui/tabs';
@use 'ui/option-card';
@use 'ui/setting-card';
@use 'ui/order-summary';
@use 'ui/distribution-summary';
@use 'ui/activation-card';
@use 'ui/snackbar';
@use 'ui/related-card';
@use 'ui/service-card';
@use 'ui/card-tag';
@use 'ui/article';
@use 'ui/date-picker';
@use 'ui/state-label';
@use 'ui/badges';
@use 'ui/card-button';
@use 'ui/sub-header';
@use 'ui/creation-card';
@use 'ui/accordion';
@use 'ui/copy-card';
@use 'ui/div-reveal';
@use 'ui/toast-card';
@use 'ui/side-action';
@use 'ui/side-information';
@use 'ui/notification-banner';
@use 'ui/horizontal-scrollable-container';
@use 'ui/list';
@use 'ui/feedback-banner';
@use 'ui/modal';
@use 'ui/timeline';
@use 'ui/carousel';
@use 'ui/action-card';
@use 'ui/home-article';
@use 'ui/select-card';
@use 'ui/expansion-panel';

@mixin style {
  @include action-card.style;
  @include additional-button.style;
  @include header.style;
  @include link.style;
  @include spinner.style;
  @include tooltip.style;
  @include button.style;
  @include header.style;
  @include table.style;
  @include field-display.style;
  @include section.style;
  @include info-card.style;
  @include validation-card.style;
  @include error-banner.style;
  @include tabs.style;
  @include option-card.style;
  @include setting-card.style;
  @include distribution-summary.style;
  @include order-summary.style;
  @include activation-card.style;
  @include snackbar.style;
  @include card-tag.style;
  @include related-card.style;
  @include service-card.style;
  @include article.style;
  @include date-picker.style;
  @include state-label.style;
  @include badges.style;
  @include card-button.style;
  @include sub-header.style;
  @include creation-card.style;
  @include accordion.style;
  @include copy-card.style;
  @include div-reveal.style;
  @include toast-card.style;
  @include side-action.style;
  @include side-information.style;
  @include notification-banner.style;
  @include horizontal-scrollable-container.style;
  @include list.style;
  @include feedback-banner.style;
  @include modal.style;
  @include timeline.style;
  @include home-article.style;
  @include carousel.style;
  @include select-card.style;
  @include expansion-panel.style;

  onep-option-card-conditions {
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: colors.grey('medium');
    margin-top: variables.$spacing;
  }

  .onep-option-selected > div {
    border: 1px solid colors.grey('light-black');
  }

  .onep-option-disabled > div {
    border: 1px solid colors.grey('light');
    background-color: colors.grey('extra-light');
    color: colors.grey('medium');
    cursor: not-allowed;

    &:hover {
      border: 1px solid colors.grey('light');
    }
  }

  onep-option-card {
    onep-card-content {
      font-size: 14px;
    }

    > div {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding: variables.$spacing * 2;
      border-radius: math.div(variables.$spacing, 2);
      border: 1px solid colors.grey('light');
      position: relative;

      &:hover {
        border: 1px solid colors.grey('medium');
      }
    }
  }
}
