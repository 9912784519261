@use 'sass:map';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../text';
@use './input';

@mixin style {
  .onep-date-field-backdrop {
    background-color: transparent;
  }
  .onep-date-picker-open {
    input {
      border: 1px solid colors.grey('light-black');
      box-shadow: inset 0 0 0 1px colors.grey('light-black');
    }
  }

  onep-date-field {
    display: flex;
    input::placeholder {
      color: colors.grey('medium');
    }
    &.onep-input-error {
      input {
        border: 1px solid colors.orange('error');
        box-shadow: inset 0 0 0 1px colors.orange('error');
        outline: none;
      }
    }
    &.onep-selectedDate-disabled {
      onep-date-field-suffix onep-field-suffix {
        pointer-events: none;
        cursor: default;
      }
    }
  }

  onep-date-field-suffix onep-field-suffix {
    cursor: pointer;
    svg {
      color: colors.grey('medium');
      font-size: field-variables.$icon-size;
    }
  }
  .onep-input-date {
    color: transparent;
    text-transform: uppercase;
    font-family: var(--description-font), sans-serif;
    &.onep-input-date-fill {
      color: colors.grey('light-black');
    }

    &:focus {
      display: flex;
      color: colors.grey('light-black');
    }
  }

  input[type='date'],
  input[type='datetime-local'],
  input[type='time'],
  input[type='month'],
  input[type='week'] {
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    outline: none;
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: variables.$spacing * 6;
    z-index: 100;
  }
}
