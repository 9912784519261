@use 'sass:math';
@use '../../variables';
@use '../../colors';

@mixin style {
  onep-numeric-field {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    position: relative;
    display: inline-block;

    .onep-numeric-input {
      width: variables.$spacing * 6 + 4;
      padding: variables.$spacing - 2 variables.$spacing * 3 + 4
        variables.$spacing - 2 variables.$spacing;
      border: 1px solid colors.grey('light');
      border-radius: math.div(variables.$spacing, 2);
      background-color: variables.$white;
      flex: 1;
      color: colors.grey('light-black');
      font-size: variables.$font-size;
      line-height: 20px;
      outline: none;

      &:disabled {
        background-color: colors.grey('extra-light');
        color: colors.grey('medium');
      }

      &:focus {
        border: 1px solid colors.grey('light-black') !important;
      }
    }
    .onep-input-error {
      border: 1px solid colors.orange('error');
      box-shadow: inset 0 0 0 1px colors.orange('error');
      outline: none;
    }

    onep-numeric-field-arrows {
      position: absolute;
      display: flex;
      flex-direction: column;
      right: variables.$spacing;
      top: 1px;

      button {
        cursor: pointer;
        padding: 0;
        background-color: transparent;
        border: inherit;
        outline: none;

        svg {
          font-size: variables.$font-size;
        }
      }

      button {
        &:disabled {
          background-color: colors.grey('extra-light');
        }
      }
    }
  }
}
