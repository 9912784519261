@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-side-information-container {
    display: flex;
    flex-direction: column;
    onep-side-information {
      display: flex;
      flex-direction: column;
    }
    onep-side-information:not(:first-child) {
      margin-top: variables.$spacing * 3;
    }
    onep-side-information:not(:last-child) {
      border-bottom: 1px solid colors.grey('light');

      .onep-link-navigation {
        margin-bottom: variables.$spacing * 2;
      }
    }
  }
  .onep-side-information-title {
    font-weight: bold;
    padding-bottom: variables.$spacing;
    padding-right: variables.$spacing * 2;
    color: map_get(variables.$grey-shades, 'extraDark');
  }

  .onep-side-information-content {
    padding-right: variables.$spacing * 2;
    padding-bottom: variables.$spacing * 2;
    color: map_get(variables.$grey-shades, 'extraDark');
  }

  .onep-side-information-link {
    align-self: flex-end;
    font-size: 14px !important;
    line-height: 20px !important;
    svg {
      font-size: 20px !important;
      margin-bottom: 2px;
    }
  }

  .onep-side-information-button {
    margin-top: variables.$spacing;
    align-self: flex-start;
  }
}
