$max-small-mobile-width: 320px;
$max-large-mobile-width: 504px;
$max-vertical-tablet-width: 768px;
$max-horizontal-tablet-width: 1023px;
$max-small-desktop-width: 1320px;
$max-medium-desktop-width: 1439px;

/*SMALL MOBILE*/
@mixin onep-small-mobile-and-down {
  @media (max-width: $max-small-mobile-width) {
    @content;
  }
}
@mixin onep-small-mobile-and-up {
  @media (min-width: 0px) {
    @content;
  }
}
@mixin onep-small-mobile-only {
  @media (min-width: 0px) and (max-width: $max-small-mobile-width) {
    @content;
  }
}

/*LARGE MOBILE*/
@mixin onep-large-mobile-and-down {
  @media (max-width: $max-large-mobile-width) {
    @content;
  }
}
@mixin onep-large-mobile-and-up {
  @media (min-width: $max-small-mobile-width+1) {
    @content;
  }
}
@mixin onep-large-mobile-only {
  @media (min-width: $max-small-mobile-width+1) and (max-width: $max-large-mobile-width) {
    @content;
  }
}

/*VERTICAL TABLET*/
@mixin onep-vertical-tablet-and-down {
  @media (max-width: $max-vertical-tablet-width) {
    @content;
  }
}
@mixin onep-vertical-tablet-and-up {
  @media (min-width: $max-large-mobile-width+1) {
    @content;
  }
}
@mixin onep-vertical-tablet-only {
  @media (min-width: $max-large-mobile-width+1) and (max-width: $max-vertical-tablet-width) {
    @content;
  }
}

/*HORIZONTAL TABLET*/
@mixin onep-horizontal-tablet-and-down {
  @media (max-width: $max-horizontal-tablet-width) {
    @content;
  }
}
@mixin onep-horizontal-tablet-and-up {
  @media (min-width: $max-vertical-tablet-width+1) {
    @content;
  }
}
@mixin onep-horizontal-tablet-only {
  @media (min-width: $max-vertical-tablet-width+1) and (max-width: $max-horizontal-tablet-width) {
    @content;
  }
}

/*SMALL DESKTOP*/
@mixin onep-small-desktop-and-down {
  @media (max-width: $max-small-desktop-width) {
    @content;
  }
}
@mixin onep-small-desktop-and-up {
  @media (min-width: $max-horizontal-tablet-width+1) {
    @content;
  }
}
@mixin onep-small-desktop-only {
  @media (min-width: $max-horizontal-tablet-width+1) and (max-width: $max-small-desktop-width) {
    @content;
  }
}

/*MEDIUM DESKTOP*/
@mixin onep-medium-desktop-and-down {
  @media (max-width: $max-medium-desktop-width) {
    @content;
  }
}
@mixin onep-medium-desktop-and-up {
  @media (min-width: $max-small-desktop-width+1) {
    @content;
  }
}
@mixin onep-medium-desktop-only {
  @media (min-width: $max-small-desktop-width+1) and (max-width: $max-medium-desktop-width) {
    @content;
  }
}

/*LARGE DESKTOP*/
@mixin onep-large-desktop-and-up {
  @media (min-width: $max-medium-desktop-width+1) {
    @content;
  }
}
