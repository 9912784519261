@use '../../text';
@use '../../variables';
@use '../../colors';

@mixin style {
  onep-table-paginator {
    .onep-table-row-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
    }
  }
  onep-table-paginator {
    display: flex;
    flex-direction: row;
    align-items: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    svg {
      font-size: 24px;
      cursor: pointer;
      &.onep-paginator-icon-disable {
        color: colors.grey('light');
        cursor: default;
        pointer-events: none;
      }
    }
    onep-table-paginator-element {
      font-size: 14px;
      margin-right: 4px;
      margin-left: 4px;
      cursor: pointer;
      color: colors.grey('medium');
      &:hover {
        color: colors.grey('dark');
      }
      &.onep-paginator-element-hide {
        display: none;
      }
      .onep-paginator-element-selected {
        font-weight: bold;
        color: colors.black();
      }
    }
  }
}
