@use 'sass:map';
@use '../../text';
@use '../../colors';
@use '../../variables';
@use '../../grid';

@mixin style {
  onep-field-display {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .onep-field-display-label {
      @include text.body-1;
      color: colors.grey('medium');
      margin-bottom: 2px;
    }
    .onep-field-display-content {
      @include grid.onep-large-mobile-and-down {
        @include text.body-1;
      }
    }
    .onep-field-display-inline {
      @include text.caption-1;
      color: colors.grey('medium');
      margin-top: 2px;
    }
  }
}
