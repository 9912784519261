@use 'sass:map';
@use '../../text';
@use '../../colors';
@use '../../grid';
@use '../../variables';

@mixin style {
  div[onepSectionForm] {
    onep-field-display:not(:last-child) {
      margin-bottom: variables.$spacing * 3;
    }
  }

  onep-section-title {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 11px;
    align-items: center;
    border-bottom: 1px solid colors.grey('light');
    margin-bottom: variables.$spacing * 3;

    .onep-button {
      margin-right: -8px;
      color: colors.grey('light-black') !important;
      .onep-button-label {
        color: colors.grey('light-black') !important;
      }
      @include grid.onep-large-mobile-and-up {
        display: inline;
      }
      @include grid.onep-large-mobile-and-down {
        display: none;
      }
      padding-bottom: 0 !important;
    }

    .onep-section-title-collapsible-caret {
      cursor: pointer;
      color: colors.red();
      font-size: 24px;
      margin-right: 8px;
      transition: transform 300ms;
    }
    .onep-section-title-collapsible-caret--down {
      transform: rotate(90deg);
    }

    .onep-section-title-title {
      display: flex;
      @include text.heading-5;
      font-weight: 700;
      padding-right: variables.$spacing * 7;
      @include grid.onep-large-mobile-and-down {
        padding-right: variables.$spacing * 4;
      }
    }

    .onep-section-title-title--clickable {
      cursor: pointer;
      &:hover {
        color: colors.grey('dark');
      }
    }
    .onep-section-title-responsive-icon {
      font-size: variables.$spacing * 3;
      color: colors.grey('dark');
      cursor: pointer;
      @include grid.onep-large-mobile-and-up {
        display: none;
      }
      @include grid.onep-large-mobile-and-down {
        display: inline;
      }
    }
  }
}
