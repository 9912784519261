@use '../../text';
@use '../../colors';
@use '../../variables';
@use '../../mixins';

@mixin style {
  @include mixins.onep-small-and-down {
    onep-error-banner {
      .onep-error-banner-text {
        margin-right: variables.$spacing * 2;
      }
    }
  }
  onep-error-banner {
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: colors.orange('light');
    @include text.body-3;
    @include mixins.onep-small-and-down {
      @include text.caption-1;
    }
    .onep-error-banner-text {
      margin-right: variables.$spacing * 1.5;
      padding-top: variables.$spacing * 1.5;
      padding-bottom: variables.$spacing * 1.5;
    }
    svg {
      margin-left: variables.$spacing * 2;
      margin-right: variables.$spacing * 1.5;
      border-radius: 50px;
      background-color: colors.orange('error');
      width: 20px;
      height: 20px;
      color: colors.white();
      font-size: 20px;
    }
  }
}
