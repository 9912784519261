@use 'sass:map';
@use 'sass:math';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use '../../mixins';
@use './input';

@mixin style {
  onep-merge-fields {
    onep-field:first-child {
      .onep-select {
        border-radius: math.div(variables.$spacing, 2)
          math.div(variables.$spacing, 2) 0 0;
        margin-bottom: 0;
        &:focus {
          box-shadow: inset 0 0 0 1px colors.grey('light-black');
        }
        &.onep-input-error {
          box-shadow: inset 0 0 0 1px colors.orange('error');
        }
      }

      .onep-input {
        width: 100%;
        border-radius: math.div(variables.$spacing, 2)
          math.div(variables.$spacing, 2) 0 0;
        margin-bottom: 0;
        &:focus {
          box-shadow: inset 0 0 0 1px colors.grey('light-black');
        }
        &.onep-input-error {
          box-shadow: inset 0 0 0 1px colors.orange('error');
        }
      }
    }
    onep-field:last-child {
      .onep-select {
        border-top-width: 0;
        border-radius: 0 0 math.div(variables.$spacing, 2)
          math.div(variables.$spacing, 2);
        &:focus {
          box-shadow: inset 0 0 0 1px colors.grey('light-black');
        }
        &.onep-input-error {
          box-shadow: inset 0 0 0 1px colors.orange('error');
        }
      }

      .onep-input {
        width: 100%;
        border-top-width: 0;
        border-radius: 0 0 math.div(variables.$spacing, 2)
          math.div(variables.$spacing, 2);
        &:focus {
          box-shadow: inset 0 0 0 1px colors.grey('light-black');
        }
        &.onep-input-error {
          box-shadow: inset 0 0 0 1px colors.orange('error');
        }
      }
    }
    &.onep-merge-fields-horizontal {
      display: flex;
      flex-direction: row;

      onep-field:first-child {
        .onep-select {
          border-radius: math.div(variables.$spacing, 2) 0 0
            math.div(variables.$spacing, 2);

          .selected-display {
            align-items: center;

            .selected-display-content {
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              flex: 1;

              .onep-select-suffix {
                position: inherit;
              }
            }
          }
          &:focus {
            box-shadow: inset 0 0 0 1px colors.grey('light-black');
          }
          &.onep-input-error {
            box-shadow: inset 0 0 0 1px colors.orange('error');
          }
        }

        onep-label {
        }
      }

      &.onep-translate-field {
        onep-field:first-child {
          flex: 0;

          onep-select {
            box-sizing: border-box;
            border-radius: math.div(variables.$spacing, 2) 0 0
              math.div(variables.$spacing, 2) !important;
            z-index: 2;

            .selected-display {
              padding: 17px 12px 17px 16px;
              @include mixins.onep-small-and-down {
                padding: 16px 16px 14px 16px;
              }
              align-items: center;
              width: 56px;

              .selected-display-content {
                display: flex;
                flex: 1;

                .onep-select-suffix {
                  position: inherit;
                }
              }
            }
            &:focus {
              box-shadow: inset 0 0 0 1px colors.grey('light-black');
            }
            &.onep-input-error {
              box-shadow: inset 0 0 0 1px colors.orange('error');
            }

            &.overlay-open {
              border-bottom-left-radius: 0 !important;
            }
          }

          onep-label {
            z-index: 4;
          }
        }
      }

      onep-field:last-child {
        .onep-input {
          width: 100%;
          margin-left: -1px;
          border-radius: 0 math.div(variables.$spacing, 2)
            math.div(variables.$spacing, 2) 0;
          border-top-width: 1px;
          &:focus {
            box-shadow: inset 0 0 0 1px colors.grey('light-black');
          }
          &.onep-input-error {
            box-shadow: inset 0 0 0 1px colors.orange('error');
          }
        }
      }

      &.onep-merge-fields-horizontal-opened {
        onep-field:first-child {
          .onep-select {
            border-bottom-left-radius: 0 !important;
          }
        }

        onep-field:last-child {
          input {
            border-bottom-right-radius: 0 !important;
          }
        }
      }
    }
  }
}
