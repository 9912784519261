@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../mixins';

@mixin style {
  onep-table-row-control {
    .onep-table-row-container {
      display: flex;
      align-items: center;

      span {
        font-size: variables.$font-size;
      }
      onep-select {
        cursor: pointer;
        border: none !important;
        border-radius: math.div(variables.$spacing, 2);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex: 1;
        display: inline-flex;

        color: colors.grey('light-black');
        font-size: variables.$font-size + 2px;

        .selected-display {
          position: relative !important;
          padding: variables.$spacing variables.$spacing * 4 - 4px
            variables.$spacing math.div(variables.$spacing, 2);
          @include mixins.onep-small-and-down {
            padding: variables.$spacing variables.$spacing * 4 - 4px
              variables.$spacing math.div(variables.$spacing, 2) !important;
          }
          height: variables.$font-size + 2px;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
        }

        .select-suffix {
          font-size: 24px;
          color: colors.grey('light-black');
        }
        .onep-select-suffix {
          position: absolute;
          right: 0;
          top: math.div(variables.$spacing, 2);
        }
        .select-suffix {
          transform: rotate(0deg);
          transition: transform 300ms;
        }

        &:focus {
          outline: none;
          box-shadow: none !important;
          border: none !important;
        }
        &.overlay-open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          .select-suffix {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
