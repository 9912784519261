@use '../grid';

@mixin style {
  .uikit-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    max-width: calc(100vw - 262px);
    @include grid.onep-large-desktop-and-up {
      max-width: 1192px;
    }
    @include grid.onep-horizontal-tablet-and-down {
      max-width: 100vw;
    }
  }
}
