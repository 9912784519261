@use '../variables';
@use '../colors';
@use '../text';

@mixin style {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  h1,
  .onep-text-heading-1 {
    @include text.heading-1;
  }

  h2,
  .onep-text-heading-2 {
    @include text.heading-2;
  }

  .onep-text-subheading-1 {
    @include text.subheading-1;
  }

  .onep-text-subheading-2 {
    @include text.subheading-2;
  }

  h3,
  .onep-text-heading-3 {
    @include text.heading-3;
  }

  h4,
  .onep-text-heading-4 {
    @include text.heading-4;
  }

  h5,
  .onep-text-heading-5 {
    @include text.heading-5;
  }

  .onep-text-body-1 {
    @include text.body-1;
  }

  .onep-text-body-2 {
    @include text.body-2;
  }

  .onep-text-body-3 {
    @include text.body-3;
  }

  .onep-text-body-4 {
    @include text.body-4;
  }

  .onep-text-body-4-uppercase {
    @include text.body-4;
    text-transform: uppercase;
  }

  .onep-text-caption-1 {
    @include text.caption-1;
  }

  .onep-text-caption-2 {
    @include text.caption-2;
  }

  .onep-text-caption-3 {
    @include text.caption-3;
  }

  .onep-text-link-1 {
    @include text.link-1;
  }

  .onep-text-link-2 {
    @include text.link-2;
  }

  .onep-text-link-3 {
    @include text.link-3;
  }

  .onep-text-button {
    @include text.body-2-bold;
  }

  .onep-text-button-small {
    @include text.button-small;
  }

  .bold {
    @include text.bold;
  }

  .medium {
    @include text.medium;
  }

  .semi-bold {
    @include text.semi-bold;
  }

  .italic {
    @include text.italic;
  }
}
