@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';

@mixin style {
  onep-info-card {
    @include text.caption-1;

    > div {
      display: flex;
      flex-direction: column;
      padding: variables.$spacing * 2 variables.$spacing variables.$spacing * 3
        variables.$spacing * 2;
      @include mixins.onep-small-and-down {
        padding: variables.$spacing * 2 variables.$spacing variables.$spacing *
          2 variables.$spacing * 2;
      }
      border-radius: math.div(variables.$spacing, 2);
      background-color: colors.grey('extra-light');
      border: 1px solid colors.grey('soft');
      position: relative;

      onep-checkbox > label {
        margin-top: -3px; // To ignore the security margins of the checkbox icon when inside the card.
        margin-left: -3px;

        align-items: flex-start !important;
      }

      .onep-checkbox-visual-content {
        @include text.caption-1;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 3px; // Align with the checkbox tick as the text size is reduced.
      }

      .onep-checkbox-native-input-container {
        justify-content: flex-start;
      }
    }
  }
  onep-card-title {
    margin-bottom: variables.$spacing;
    font-weight: bold;
    color: colors.grey('light-black');
  }

  onep-card-content {
    color: colors.grey('light-black');
  }
}
