@use '../../colors';
@use '../../text';
@use 'datatable';

@mixin style {
  onep-datatable-selection-header {
    background-color: datatable.$header-cell-bgcolor;

    position: absolute;
    top: 0;
    left: datatable.$selection-column-full-width;

    height: datatable.$header-height;
    width: calc(
      100% - #{datatable.$selection-column-full-width + 2 *
        datatable.$header-cell-border-padding}
    );

    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: datatable.$cell-horizontal-padding;
    padding-right: datatable.$cell-horizontal-padding;

    .selection-text {
      @include text.body-1();
      font-weight: 500;

      flex: 1;
    }

    .custom-content {
      flex: 0 0 auto;
    }
  }
}
