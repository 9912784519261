@use '../../variables';
@use '../../colors';
@use '../../text';
@use '../../mixins';

@mixin style {
  onep-div-reveal {
    display: inherit;
    position: absolute;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: auto;
    transition: all ease-in-out 300ms;
    &.onep-div-reveal-hide {
      height: 0;
      padding: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .onep-div-reveal-content {
      width: 100%;
      text-align: left;
      background-color: colors.grey('extra-light');
      padding-top: variables.$spacing * 3;
      padding-left: variables.$spacing * 3;
      padding-right: variables.$spacing * 7;
    }
    svg[onepIcon] {
      z-index: 1;
      font-size: variables.$spacing * 3;
      position: absolute;
      right: variables.$spacing * 1.5;
      top: variables.$spacing * 1.5;
      color: colors.grey('medium');
      cursor: pointer;
      @include mixins.onep-small-and-down {
        right: variables.$spacing * 1.5;
        top: variables.$spacing * 1.5;
      }
    }
  }
}
