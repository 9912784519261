@use 'sass:math';
@use '../../variables';
@use '../../colors';

@mixin style {
  onep-simple-snackbar {
    &.onep-mobile-snack {
      width: 100%;
      max-width: 100%;

      onep-snackbar {
        width: 100%;
        max-width: 100%;
        .snackbar-container {
          flex: 1;
          border-radius: 0;
          justify-content: space-between;
        }
      }
    }
  }

  onep-snackbar {
    min-height: 6 * variables.$spacing + math.div(variables.$spacing, 2);
    max-width: 50 * variables.$spacing;
    display: flex;

    .snackbar-container {
      display: flex;
      background-color: colors.grey('extra-light');
      flex-direction: row;
      border: solid 1px colors.grey('light');
      border-radius: 4px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;

      .snackbar-text-container {
        margin-top: math.div(variables.$spacing * 3, 2) + 2px;
        margin-bottom: variables.$spacing * 2;
        font-weight: normal;

        onep-snackbar-title {
          font-weight: 700;
        }
      }

      onep-snackbar-icon {
        flex: 0 0 24px;
        margin-top: math.div(variables.$spacing * 3, 2);
        font-size: 24px;
        margin-left: math.div(variables.$spacing * 3, 2);
        margin-right: math.div(variables.$spacing, 2);
        &.icon-success-color {
          color: colors.green('success');
        }
        &.icon-error-color {
          color: colors.orange('error');
        }
      }

      .snackbar-close {
        flex: 0 0 16px;
        padding-right: math.div(variables.$spacing, 2);
        padding-top: math.div(variables.$spacing, 2);
        margin-left: variables.$spacing * 2;
        font-size: variables.$font-size + 2px;
        color: colors.grey('medium');
        cursor: pointer;
      }
    }
  }
}
