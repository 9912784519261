@use 'sass:math';
@use '../../variables';
@use '../../colors';
@use '../../text';

@mixin style {
  onep-related-card {
    box-shadow: none;
    transition: box-shadow 200ms;
    cursor: pointer;
    position: relative;
    display: flex;

    onep-card-tag {
      right: -5px;
      top: 16px;
    }

    onep-related-card-title {
      display: flex;
      @include text.caption-3();
      color: colors.grey('medium');
      margin-bottom: 24px;
      margin-left: math.div(variables.$spacing, 2);
      max-width: 132px;
    }

    onep-related-card-cta {
      display: flex;
      align-items: center;
      flex-direction: row;
      @include text.heading-5();
      font-weight: 700;
      margin-bottom: 8px;
      img {
        width: 40px;
        margin-right: variables.$spacing;
      }
    }

    onep-related-card-body {
      margin-left: math.div(variables.$spacing, 2);
    }

    .onep-related-card-cta-caret {
      transform: rotate(270deg);
      color: colors.grey('medium');
      min-height: 24px;
      min-width: 24px;
      font-size: 24px;
    }

    @include text.body-3();
    color: colors.grey('medium');
    flex-direction: column;
    padding: (variables.$spacing * 3) (variables.$spacing * 2)
      (variables.$spacing * 3) (variables.$spacing * 1.5);
    background-color: colors.white();
    border: 1px solid colors.grey('soft');
    border-radius: math.div(variables.$spacing, 2);
  }

  onep-related-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 200ms;
  }
}
