@use 'sass:map';
@use 'sass:math';
@use '../../variables';
@use './field-variables';
@use '../../colors';
@use './input';

@mixin style {
  .onep-code-field {
    &.onep-field-mobile {
      input {
        height: field-variables.$input-height-mobile;
      }
    }
    display: flex;
    p {
      margin-top: variables.$spacing;
      color: map.get(variables.$secondary-colors, 'errorOrange');
      display: block;
    }
    input {
      box-sizing: border-box;
      align-items: center;
      position: relative;
      flex: 1;
      font-size: field-variables.$input-font-size + 2px;
      line-height: 20px;
      text-align: center;
      margin-right: 8px;
      width: 40px;
      height: field-variables.$input-height;
      -moz-appearance: textfield;
      border: 1px solid colors.grey('light');
      border-radius: math.div(variables.$spacing, 2);
      color: colors.grey('light-black');
      outline: none;
      &:focus {
        border: 2px solid colors.grey('light-black');
        outline: none;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &.onep-input-error {
      input {
        border: 1px solid map.get(variables.$secondary-colors, 'errorOrange');
        box-shadow: inset 0 0 0 1px
          map.get(variables.$secondary-colors, 'errorOrange');
        outline: none;
      }
    }
  }
}
